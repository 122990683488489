const project = {
  form: {
    title: 'Présentez-nous votre projet',
    savedMessage: 'Les changements ont bien été enregistrés',
    summary: {
      title: 'Présentez-nous votre projet',
      field: {
        title: 'Titre',
        titlePlaceholder: 'Entrez le nom de votre projet...',
        descriptionTitle: 'Décrivez-nous votre projet',
        description: 'Description rapide',
        descriptionPlaceholder: 'Quels sont les objectifs de votre projet?\nex "je souhaite augmenter notre notoriété ... et pour cela nous avons besoin de ...", "nous recherchons idéalement ..."',
        status: 'Statut',
        statusPlaceholder: 'Choisir',
        budget: 'Votre budget',
        budgetPlaceholder: 'Définissez votre budget (précisez CAD ou €)'
      }
    },
    documents: {
      title: 'Votre projet en détails',
      subTitle: 'Pour vous aider à décrire votre projet le plus précisément possible, vous pouvez télécharger et compléter un des documents modèle ci-dessous, ou bien joindre un cahier des charges existant',
      stub: {
        title: 'Nos documents modèles à télécharger',
        text: 'Vous pouvez prendre le temps de compléter ce document et finaliser votre soumission de projet plus tard en mettant à jour le statut du projet avant de valider le formulaire'
      },
      upload: {
        title: 'Ajoutez un document modèle complété et/ou votre propre cahier des charges',
        text: 'Besoin d\'aide pour décrire votre projet ? Nos équipes peuvent vous aider à compléter les informations nécessaires au (+1)514-726-8406',
        addButtonLabel: 'Ajouter un document'
      }
    },
    document: {
      contentTemplate: {
        label: 'Rédaction de contenu'
      },
      designTemplate: {
        label: 'Création de design graphique'
      },
      strategyTemplate: {
        label: 'Stratégie marketing'
      },
      socialTemplate: {
        label: 'Gestion de médias sociaux'
      },
      websiteTemplate: {
        label: 'Création de site web'
      }
    },
    consultations:{
      title:'Consultations',
      description:'Merci de me proposer des Talents M pour une consultation en vue d’affiner mes besoins pour ce projet.',
      details:'Plus vous enrichissez votre équipe consultative plus les leviers seront importants, nous vous conseillons de choisir des talents complémentaires.'
    },
    skills: {
      title: 'Quelles expertises recherchez-vous ?'
    },
    save: {
      title: 'Publication de votre projet'
    },
    saveButtonLabel: 'Enregister le projet'
  },
  tile: {
    title: 'Voici des talents qui pourraient vous intéresser !',
    startedOn: 'Projet lancé le',
    startedBy: 'par',
    seeProjectButtonLabel: 'Voir le projet',
    editProjectButtonLabel: 'Editer'
  },
  list: {
    title: 'Trouver des projets',
    description: 'Sélectionne les domaines marketing de ton choix et découvre les entreprises à la recherche des meilleurs talents pour réaliser leurs projets!',
    resultTitle: 'Voici des projets qui pourraient t\'intéresser!',
    noProjectToShow: 'Il n\'y a pas encore de projet à voir',
    noProjectFound: '🧐 Nous n\'avons trouvé aucun projet correspondant à ta recherche.',
  },
  errors: {
    title: {
      required: 'Ce champ est obligatoire'
    },
    description: {
      required: 'Ce champ est obligatoire'
    },
    budget: {
      required: 'Ce champ est obligatoire'
    },
    status: {
      required: 'Ce champ est obligatoire'
    },
    skills: {
      selectAtLeastOne: 'Choisir au moins une compétence'
    }
  },
  details: {
    notFound: 'Le projet demandé n\'existe pas',
    publishedByLabel: 'Projet publié par',
    publishedDateLabel: 'le',
    aboutProjectLabel: 'À propos de',
    budgetLabel: 'Budget:',
    documentsTitle: 'Documents du projet',
    callToAction: 'Ce projet t\'intéresse? Prends contact avec l\'équipe de les Talents M en leur adressant un courriel à',
    contact:'contact@lestalentsm.com'
  }
};

export default project;
