import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

class EvaluatorForm extends Component {

  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(score) {
    this.props.onValueSelected(this.props.referenceId, score);
  }

  star() {
    return <svg viewBox="0 0 1200 1200"><defs><linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" stopColor="#8B408F"/><stop offset="100%" stopColor="#F03A40"/></linearGradient></defs><circle cx="600" cy="600" r="400" fill="url(#linear)" stroke="#fb3034" strokeWidth="50" /></svg>;
  }

  displayError() {
    if (this.props.error) {
      return (
        <p className="error-message">
          { this.props.error }
        </p>
      );
    }
    return;
  }

  render() {
    return (
      <div className="circles-group">
        <ol data-current-value={ this.props.currentScore || 0 }>
          <li onClick={ () => this.updateValue('1') } title="1">{ this.star() }</li>
          <li onClick={ () => this.updateValue('2') } title="2">{ this.star() }</li>
          <li onClick={ () => this.updateValue('3') } title="3">{ this.star() }</li>
          <li onClick={ () => this.updateValue('4') } title="4">{ this.star() }</li>
          <li onClick={ () => this.updateValue('5') } title="5">{ this.star() }</li>
          <li className="rate-helper">
            <p>{ I18n.t('worker.profile.form.skills.rateHelper') }</p>
            <i className="fa fa-question-circle"></i>
          </li>
        </ol>
        { this.displayError() }
      </div>
    );
  }
}

EvaluatorForm.propTypes = {
  referenceId: PropTypes.number,
  currentScore: PropTypes.string,
  onValueSelected: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default EvaluatorForm;
