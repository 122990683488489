import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { loadCompanyProfile } from '../../reducers/company';

import { CompanyShape } from '../../model/Company';

class WorkerHeaderSwitchProfile extends Component {

  componentDidMount() {
    this.props.loadCompanyProfile(this.props.user.uid);
  }

  showCallToAction() {
    if (this.props.companyProfile && this.props.companyProfile.isValid()) {
      return <Link to="/company" className="btn transparent"><span>{ I18n.t('nav.worker.switchProfileButtonLabel') }</span></Link>;
    } else {
      return <Link to="/company/profile" className="btn transparent"><span>{ I18n.t('nav.worker.createCompanyProfileButtonLabel') }</span></Link>;
    }
  }

  render() {
    if (!this.props.companyProfileLoading) {
      return (
        <div className="grid-item worker-switch">
          <p><em>{ I18n.t('nav.worker.publishProjectLabel') }</em></p>
          { this.showCallToAction() }
        </div>
      );

    }
    return null;
  }
}

WorkerHeaderSwitchProfile.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  companyProfile: CompanyShape,
  companyProfileLoading: PropTypes.bool.isRequired,
  loadCompanyProfile: PropTypes.func.isRequired
};

WorkerHeaderSwitchProfile.defaultProps = {
  user: undefined,
  profileLoading: true
};

const mapStateToProps = state => ({
  companyProfile: state.company.profile,
  companyProfileLoading: state.company.profileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanyProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkerHeaderSwitchProfile);
