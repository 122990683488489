import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { ProjectSummaryShape } from '../../model/ProjectSummary';

import iconProject from '../../assets/img/icon-project.svg';

class ProjectSummaryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: props.summary.title || '',
      description: props.summary.description || '',
      budget: props.summary.budget || ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setSummary(newState);
    this.setState(newState);
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p>{ this.props.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div className="grid">

        <div className="form has-bg with-bottom">
          <div className={ `field-wrapper ${this.getErrorClass('title')}` }>
            <div className="field title-input">
              <img src={iconProject} alt=""/>
              <label htmlFor="title">{ I18n.t('project.form.summary.field.title') }</label>
              <input
                id="title"
                type="text"
                value={ this.state.title }
                onChange={ this.handleChange }
                placeholder={ I18n.t('project.form.summary.field.titlePlaceholder') }
              />
              { this.showError('title') }
            </div>
          </div>
        </div>

        <div className="form has-bg">
          <h3>{ I18n.t('project.form.summary.field.descriptionTitle') }</h3>
          <div className={ `field-wrapper ${this.getErrorClass('description')}` }>
            <div className="field">
              <label htmlFor="description">{ I18n.t('project.form.summary.field.description') }</label>
              <textarea
                id="description"
                type="text"
                value={ this.state.description }
                onChange={ this.handleChange }
                placeholder={ I18n.t('project.form.summary.field.descriptionPlaceholder') }
              />
              { this.showError('description') }
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('budget')}` }>
            <div className="field">
              <label htmlFor="budget">{ I18n.t('project.form.summary.field.budget') }</label>
              <input
                id="budget"
                type="text"
                value={ this.state.budget }
                onChange={ this.handleChange }
                placeholder={ I18n.t('project.form.summary.field.budgetPlaceholder') }
              />
              { this.showError('budget') }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectSummaryForm.propTypes = {
  summary: ProjectSummaryShape,
  setSummary: PropTypes.func.isRequired,
  errors: PropTypes.shape({})
};

ProjectSummaryForm.defaultProps = {
  summary: undefined
};

export default ProjectSummaryForm;
