import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProjectForm from './ProjectForm';

import { loadAllProjects } from '../../reducers/project';

import { ProjectShape } from '../../model/Project';

class EditProjectForm extends Component {

  componentDidMount() {
    this.props.loadAllProjects();
  }
  
  findProject() {
    return this.props.projects.find(project => project.id === this.props.match.params.projectId);
  }

  render() {
    const project = this.findProject();
    if (project) {
      return <ProjectForm project={ project } />;
    }
    return null;
  }
}

EditProjectForm.propTypes = {
  projects: PropTypes.arrayOf(ProjectShape),
  loadAllProjects: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string
    })
  })
};

EditProjectForm.defaultProps = {
  projects: []
};

const mapStateToProps = state => ({
  projects: state.project.projects
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAllProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectForm);
