import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class WorkerNavigationBar extends Component {

  getItemClassName(itemPath) {
    if (itemPath === this.props.currentPath) {
      return 'active';
    }
  }

  render() {
    return (
      <div className="grid-item">
        <ul>
          <li className={ this.getItemClassName('/talent') }>
            <Link to="/talent">Tableau de bord</Link>  
          </li>
          <li className={ this.getItemClassName('/talent/projects') }>
            <Link to="/talent/projects">Projets</Link>  
          </li>
          <li className={ this.getItemClassName('/talent/profiles') }>
            <Link to="/talent/profiles">Talents</Link>  
          </li>
          <li className={ this.getItemClassName('/talent/profile') }>
            <Link to="/talent/profile">Mes informations</Link>
          </li>
        </ul>
      </div>
    );
  }
}

WorkerNavigationBar.propTypes = {
  currentPath: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  currentPath: state.routing.location.pathname
})

export default connect(mapStateToProps)(WorkerNavigationBar);
