import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { loadActivityFields } from '../../reducers/data';

class CompanyActivityFieldSelectorForm extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      selectedField: ''
    };
  }

  componentWillMount() {
    if (this.props.activityFields.length === 0) {
      this.props.loadActivityFields();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== '' && nextProps.value !== this.state.selectedField) {
      this.setState(Object.assign({}, this.state, { selectedField: nextProps.value }));
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { selectedField: event.target.value });
    this.props.setValue(newState.selectedField);
    this.setState(newState);
  }

  buildOptions() {
    if (!this.props.activityFieldsLoading) {
      return this.props.activityFields.map((field, index) => {
        return <option value={ field.id } key={ index }>{ field.label }</option>
      });
    }
  }

  render() {
    return (
      <div className="field-wrapper">
        <div className="field">
          <select onChange={ this.handleChange } value={ this.state.selectedField } >
            <option value="">{ I18n.t('company.profile.form.activityFields.placeholder') }</option>
            { this.buildOptions() }
          </select>
        </div>
      </div>
    );
  }
}

CompanyActivityFieldSelectorForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  activityFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activityFieldsLoading: PropTypes.bool.isRequired,
  loadActivityFields: PropTypes.func.isRequired
};

CompanyActivityFieldSelectorForm.defaultProps = {
  value: '',
  activityFields: [],
  activityFieldsLoading: true
};

const mapStateToProps = state => ({
  activityFields: state.data.activityFields,
  activityFieldsLoading: state.data.activityFieldsLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadActivityFields
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompanyActivityFieldSelectorForm)
