import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import FirebaseEmailLoginForm from './FirebaseEmailLoginForm';
// import FirebaseFacebookConnectForm from './FirebaseFacebookConnectForm';
//import FirebaseLinkedInConnectForm from './FirebaseLinkedInConnectForm';

class SignIn extends Component {

  render() {
    // Temporarily removed Facebook signin and LinkedIn (see comment below for LinkedIn)
    //   <div className="form form--login-social">
    //   <p><span>{ I18n.t('login.socialLoginSectionTitle') }</span></p>

    //   <div className="btn">
    //     <FirebaseFacebookConnectForm
    //       redirectOnSuccess={this.props.showHomePage}
    //     />
    //   </div>
    // </div>

    //Temporarily removed LinkedIn signin (after Facebook login)
    //<div className="btn-group">
    //<FirebaseFacebookConnectForm
    //  redirectOnSuccess={this.props.showHomePage}
    ///>
    //<FirebaseLinkedInConnectForm
    //redirectOnSuccess={this.props.showHomePage}
    ///>
    //</div>
    return (
      <div>
        <h1>{I18n.t('login.title')}</h1>

        <FirebaseEmailLoginForm redirectOnSuccess={this.props.showHomePage} />


        <p>
          { I18n.t('login.signup.label') } <a onClick={() => this.props.showSignUpPage()}>{ I18n.t('login.signup.linkLabel') }</a>
          <br/>
          <a onClick={() => this.props.showForgotPasswordPage()}>{ I18n.t('login.forgotPassword.linkLabel') }</a>
        </p>
      </div>
    );
  }
}

SignIn.propTypes = {
  showHomePage: PropTypes.func.isRequired,
  showSignUpPage: PropTypes.func.isRequired,
  showForgotPasswordPage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  showHomePage: () => push('/'),
  showSignUpPage: () => push('/signup'),
  showForgotPasswordPage: () => push('/forgot-password')
}, dispatch)

export default connect(null, mapDispatchToProps)(SignIn)
