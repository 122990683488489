import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { i18nReducer } from 'react-redux-i18n';
import authentication from './authentication';
import company from './company';
import data from './data';
import worker from './worker';
import project from './project';

export default combineReducers({
  routing: routerReducer,
  i18n: i18nReducer,
  authentication,
  data,
  worker,
  company,
  project
})