import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import FileUploaderForm from './FileUploaderForm';

import { WorkerSummaryShape } from '../../model/WorkerSummary';

class WorkerProfileSummaryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      title: '',
      city: '',
      description: '',
      keyPhrase: '',
      picture: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePictureUploaded = this.handlePictureUploaded.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.summary && Object.keys(nextProps.summary).length > 0) {
      this.setState(Object.assign({}, this.state, nextProps.summary));
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setSummary(newState);
    this.setState(newState);
  }

  handlePictureUploaded(picture) {
    const newState = Object.assign({}, this.state, { picture });
    this.props.setSummary(newState);
    this.setState(newState);
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p className="error-message">{ this.props.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div className="form has-bg">
        <div className={ `field-wrapper half ${this.getErrorClass('name')}` }>
          <label htmlFor="name">{ I18n.t('worker.profile.form.summary.field.fullName') }</label>
          <div className="field">
            <input
              id="name"
              type="text"
              value={ this.state.name }
              onChange={ this.handleChange }
              placeholder={ I18n.t('worker.profile.form.summary.field.fullNamePlaceholder') }
            />
          </div>
          { this.showError('name') }
        </div>

        <div className="field-wrapper">
          <FileUploaderForm
            fileUploadedCallback={ this.handlePictureUploaded }
            uploadDirectory="profiles"
            allowedFileType="image/*"
            label={ I18n.t('worker.profile.form.summary.field.picture') }
          />
        </div>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('title')}` }>
            <label htmlFor="title">{ I18n.t('worker.profile.form.summary.field.profileTitle') }</label>
            <div className="field">
              <input
                id="title"
                type="text"
                value={ this.state.title }
                onChange={ this.handleChange }
                placeholder={ I18n.t('worker.profile.form.summary.field.profileTitlePlaceholder') }
              />
            </div>
            { this.showError('title') }
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('city')}` }>
            <label htmlFor="city">{ I18n.t('worker.profile.form.summary.field.city') }</label>
            <div className="field">
              <input
                id="city"
                type="text"
                value={ this.state.city }
                onChange={ this.handleChange }
                placeholder={ I18n.t('worker.profile.form.summary.field.cityPlaceholder') }
              />
            </div>
            { this.showError('city') }
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('description')}` }>
          <label htmlFor="description">{ I18n.t('worker.profile.form.summary.field.description') }</label>
          <div className="field">
            <textarea
              id="description"
              value={ this.state.description }
              onChange={ this.handleChange }
              placeholder={ I18n.t('worker.profile.form.summary.field.descriptionPlaceholder') }>
            </textarea>
          </div>
          { this.showError('description') }
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('keyPhrase')}` }>
          <label htmlFor="keyPhrase">{ I18n.t('worker.profile.form.summary.field.keyPhrase') }</label>
          <div className="field">
            <input
              id="keyPhrase"
              type="text"
              value={ this.state.keyPhrase }
              onChange={ this.handleChange }
              placeholder={ I18n.t('worker.profile.form.summary.field.keyPhrasePlaceholder') }
            />
          </div>
          { this.showError('keyPhrase') }
        </div>
      </div>
    );
  }
}

WorkerProfileSummaryForm.propTypes = {
  summary: WorkerSummaryShape,
  setSummary: PropTypes.func.isRequired,
  errors: PropTypes.shape({})
};

WorkerProfileSummaryForm.defaultProps = {
  summary: undefined
};

export default WorkerProfileSummaryForm;
