import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { loadCompanyProfile } from '../../reducers/company';
import { loadCompanyProjects } from '../../reducers/project';

import { CompanyShape } from '../../model/Company';
import { ProjectShape } from '../../model/Project';

import ProjectList from '../containers/ProjectList';

import iconCompleteProfile from '../../assets/img/icon-complete-profile.svg';
import iconClose from '../../assets/img/icon-close.svg';

class CompanyDashboardSummary extends Component {

  componentDidMount() {
    this.props.loadCompanyProfile(this.props.user.uid);
    this.props.loadCompanyProjects(this.props.user.uid);
  }

  showNotification() {
    if (!this.props.companyProfile || !this.props.companyProfile.isValid()) {
      return (
        <div className="grid-item">
          <section className="section section--tile notification">
            <div className="btn--close">
              <img src={iconClose} alt=""/>
            </div>
            <div className="content">
              <img src={iconCompleteProfile} alt=""/>
              <h3>{ I18n.t('dashboard.company.notification.title') }</h3>
              <p>{ I18n.t('dashboard.company.notification.text') }</p>
              <Link to="/company/profile" className="btn small reverse"><span>{ I18n.t('dashboard.company.notification.linkLabel') }</span></Link>
            </div>
          </section>
        </div>
      );
    }
    return null;
  }

  showMyProjects() {
    if (this.props.companyProjects && this.props.companyProjects.length > 0) {
      return <ProjectList projects={ this.props.companyProjects } filterUnpublishedProjects={ false } />;
    }
    return (
      <div className="content">
        <img src={iconCompleteProfile} alt=""/>
        <h3>{ I18n.t('dashboard.company.noProject.title') }</h3>
        <p>{ I18n.t('dashboard.company.noProject.text') }</p>
        <Link to="/company/project" className="btn small reverse"><span>{ I18n.t('dashboard.company.noProject.linkLabel') }</span></Link>
      </div>
    );
  }

  showMyProjectsSection() {
    if (this.props.companyProfile && this.props.companyProfile.isValid()) {
      return (
        <div className="grid-item">
          <h2><span>{ I18n.t('dashboard.company.myProjects') }</span></h2>
          <section className="section section--tile">
            { this.showMyProjects() }
          </section>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="grid">
        { this.showNotification() }
        { this.showMyProjectsSection() }
      </div>
    );
  }
}

CompanyDashboardSummary.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  companyProfile: CompanyShape,
  companyProfileLoading: PropTypes.bool,
  companyProjects: PropTypes.arrayOf(ProjectShape),
  companyProjectsLoading: PropTypes.bool,
  loadCompanyProfile: PropTypes.func.isRequired,
  loadCompanyProjects: PropTypes.func.isRequired
};

CompanyDashboardSummary.defaultProps = {
  companyProfile: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  companyProfile: state.company.profile,
  companyProfileLoading: state.company.profileLoading,
  companyProjects: state.project.companyProjects,
  companyProjectsLoading: state.project.companyProjectsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanyProfile,
  loadCompanyProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboardSummary);
