import graph from 'fb-react-sdk';

import firebase from '../config/firebase';

import Worker from '../model/Worker';

const LOADED_ALL_WORKER_PROFILE = 'worker/LOADED_ALL_WORKER_PROFILE';
const LOADING_ALL_WORKER_PROFILE = 'worker/LOADING_ALL_WORKER_PROFILE';
const LOADED_WORKER_PROFILE = 'worker/LOADED_WORKER_PROFILE';
const LOADING_WORKER_PROFILE = 'worker/LOADING_WORKER_PROFILE';
const SAVING_WORKER_PROFILE = 'worker/SAVING_WORKER_PROFILE';
const SAVED_WORKER_PROFILE = 'worker/SAVED_WORKER_PROFILE';

const initialState = {
  profilesLoading: true,
  profiles: undefined,
  profileLoading: true,
  profile: undefined,
  profileSaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADED_ALL_WORKER_PROFILE:
      return Object.assign({}, state, {
        profilesLoading: false,
        profiles: action.payload
      });

    case LOADING_ALL_WORKER_PROFILE:
      return Object.assign({}, state, {
        profilesLoading: true
      });

    case LOADED_WORKER_PROFILE:
      return Object.assign({}, state, {
        profileLoading: false,
        profileSaved: false,
        profile: action.payload
      });

    case LOADING_WORKER_PROFILE:
      return Object.assign({}, state, {
        profileLoading: true
      });

    case SAVING_WORKER_PROFILE:
      return Object.assign({}, state, {
        profileSaved: false
      });

    case SAVED_WORKER_PROFILE:
      return Object.assign({}, state, {
        profile: action.payload,
        profileSaved: true
      });

    default:
      return state;
  }
}

export function initializeProfile(userId, userData) {
  return dispatch => {
    const database = firebase.database();
    database.ref('profiles/' + userId).once('value').then(snapshot => {
      const currentProfile = snapshot.val();
      const summary = { name: userData.name };

      if (!currentProfile && userData.id) {
        graph.get(`/${userData.id}/picture?redirect=false&type=large`, (error, response) => {
          if (!error) {
            summary.picture = response.data.url;
          }
        });
      }

      database.ref('profiles/' + userId).set({ summary });
    });
    return dispatch(loadWorkerProfile(userId));
  };
}

export function loadAllWorkers() {
  return dispatch => {
    dispatch({ type: LOADING_ALL_WORKER_PROFILE });

    const database = firebase.database();
    database.ref('profiles/').once('value').then(snapshot => {
      const workers = snapshot.val();
      return dispatch({
        type: LOADED_ALL_WORKER_PROFILE,
        payload: rawWorkersToObjectList(workers)
      });
    });
  };
}

export function loadWorkerProfile(userId) {
  return dispatch => {
    dispatch({ type: LOADING_WORKER_PROFILE });

    const database = firebase.database();
    database.ref('profiles/' + userId).once('value').then(snapshot => {
      let profile = undefined;
      const profileData = snapshot.val();
      if (profileData) {
        profile = new Worker(Object.assign(profileData, { id: userId }));
      } else {
        profile = new Worker({ id: userId });
      }
      return dispatch({
        type: LOADED_WORKER_PROFILE,
        payload: profile
      });
    });
  };
}

export function saveWorkerProfile(profile, userId) {
  return dispatch => {
    dispatch({ type: SAVING_WORKER_PROFILE });
    
    const database = firebase.database();
    database.ref('profiles/' + userId).set(profile);

    return dispatch({
      type: SAVED_WORKER_PROFILE,
      payload: profile
    });
  };
}

function rawWorkersToObjectList(rawWorkers) {
  return Object.keys(rawWorkers).map(id => new Worker(Object.assign({}, rawWorkers[id], { id })));
}