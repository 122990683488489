import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

// import FirebaseFacebookConnectForm from '../forms/FirebaseFacebookConnectForm';
//import FirebaseLinkedInConnectForm from '../forms/FirebaseLinkedInConnectForm';

import logoWhite from '../../assets/img/logo-white.svg';

class SocialSignUp extends Component {

  render() {
    // Temporarily removed Facebook Sign up and LinkedIn (see comment below for LinkedIn)
    // <div className="form form--login-social">
    //   <p><span>{ I18n.t('signup.subTitle') }</span></p>

    //   <div className="btn">
    //     <FirebaseFacebookConnectForm
    //       redirectOnSuccess={this.props.showHomePage}
    //     />
    //   </div>
    // </div>

    // Add this under <div className="form form--register-email">
    // <p><span>{ I18n.t('signup.or') }</span></p>


    //Temporarily removed LinkedIn signin (after Facebook login)
    //<div className="btn-group">
    //<FirebaseFacebookConnectForm
    //  redirectOnSuccess={this.props.showHomePage}
    ///>
    //<FirebaseLinkedInConnectForm
    //redirectOnSuccess={this.props.showHomePage}
    ///>
    //</div>
    return (
      <div className="page page--register">
        <section className="section section--splash">
          <img src={logoWhite} alt="Les Talents M." />
        </section>

        <section className="section section--register">
          <div>
            <h1>{I18n.t('signup.title')}</h1>

            <div className="form form--register-email">
              <button onClick={() => this.props.showEmailSignUpPage()} className="btn reverse">
                <span>{ I18n.t('signup.withEmail') }</span>
              </button>
            </div>

            <p>{ I18n.t('signup.alreadyHaveAnAccount') } <a onClick={ () => this.props.showHomePage() }>{ I18n.t('signup.connexionLinkLabel') }</a></p>
          </div>
        </section>
      </div>
    );
  }
}

SocialSignUp.propTypes = {
  showHomePage: PropTypes.func.isRequired,
  showEmailSignUpPage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  showHomePage: () => push('/'),
  showEmailSignUpPage: () => push('/email-signup'),
}, dispatch)

export default connect(null, mapDispatchToProps)(SocialSignUp)
