import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

const KEY_PHRASE_MAX_LENGHT = 120;

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class WorkerSummary {

  constructor(summaryInfo) {
    this.name = summaryInfo.name || '';
    this.title = summaryInfo.title || '';
    this.city = summaryInfo.city || '';
    this.description = summaryInfo.description || '';
    this.keyPhrase = summaryInfo.keyPhrase || '';
    this.picture = summaryInfo.picture || '';
  }

  equals(other) {
    return (
      !!other &&
      this.name === other.name &&
      this.title === other.title &&
      this.city === other.city &&
      this.description === other.description &&
      this.keyPhrase === other.keyPhrase &&
      this.picture === other.picture
    );
  }

  isValid() {
    return (
      notEmpty(this.name) &&
      notEmpty(this.title) &&
      notEmpty(this.city) &&
      notEmpty(this.description) &&
      notEmpty(this.keyPhrase) &&
      this.keyPhrase.length <= KEY_PHRASE_MAX_LENGHT
    );
  }

  getErrors() {
    const errors = {};

    if (!notEmpty(this.name)) {
      errors.name = I18n.t('worker.errors.summary.name.required');
    }

    if (!notEmpty(this.title)) {
      errors.title = I18n.t('worker.errors.summary.title.required');
    }

    if (!notEmpty(this.city)) {
      errors.city = I18n.t('worker.errors.summary.city.required');
    }

    if (!notEmpty(this.description)) {
      errors.description = I18n.t('worker.errors.summary.description.required');
    }

    if (!notEmpty(this.keyPhrase)) {
      errors.keyPhrase = I18n.t('worker.errors.summary.keyPhrase.required');
    } else if (this.keyPhrase.length > KEY_PHRASE_MAX_LENGHT) {
      errors.keyPhrase = I18n.t('worker.errors.summary.keyPhrase.tooLong');
    }

    return errors;
  }
}

export default WorkerSummary;

export const WorkerSummaryShape = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  city: PropTypes.string,
  description: PropTypes.string,
  keyPhrase: PropTypes.string
});