import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import ProjectList from './ProjectList';
import SkillsFilter from '../components/SkillsFilter';

import { loadAllProjects } from '../../reducers/project';

import { ProjectShape } from '../../model/Project';

class ProjectBrowser extends Component {

  constructor(props) {
    super(props);

    this.updateFilters = this.updateFilters.bind(this);

    this.state = {
      filters: []
    };
  }

  componentDidMount() {
    this.props.loadAllProjects();
  }

  updateFilters(filters) {
    this.setState(Object.assign({}, this.state, { filters }));
  }

  getVisibleProjects() {
    return this.props.projects.filter(project => project.status !== 0);
  }

  render() {
    if (this.props.projects.length > 0) {
      return (
        <div className="grid">
          <h2><span>{ I18n.t('project.list.title') }</span></h2>
          <p className="description">{ I18n.t('project.list.description') }</p>
          <SkillsFilter
            selectedSkills={ this.state.filters }
            updateFilters={ this.updateFilters }
            enableCustomFilters={ true }
          />
          <h2><span>{ I18n.t('project.list.resultTitle') }</span></h2>
          <ProjectList projects={ this.getVisibleProjects() } filters={ this.state.filters } />
        </div>
      );
    }
    return <p>{ I18n.t('project.list.noProjectToShow') }</p>
  }
}

ProjectBrowser.propTypes = {
  loadAllProjects: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(ProjectShape),
  projectsLoading: PropTypes.bool
};

ProjectBrowser.defaultProps = {
  projects: [],
  projectsLoading: true
};

const mapStateToProps = state => ({
  projects: state.project.projects,
  projectsLoading: state.project.projectsLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAllProjects
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBrowser);
