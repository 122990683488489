import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { loadCurrencies } from '../../reducers/data';

class WorkerProfileBillingForm extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.state = {
      tarification: '',
      acceptPredefinedBudget: '',
      consultationRate: '',
      currency: '',
      currencies: '',
      currenciesLoading: false,
    };
  }

  componentDidMount() {
    this.props.loadCurrencies();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.billing && nextProps.billing.tarification) {
      this.setState(nextProps.billing);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setBilling(newState);
    this.setState(newState);
  }

  handleRadioChange(event) {
    const newState = Object.assign({}, this.state, { acceptPredefinedBudget: event.target.value === 'yes' });
    this.props.setBilling(newState);
    this.setState(newState);
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p>{ this.props.errors[fieldName] }</p>;
    }
  }

  buildOptions() {
    if (!this.props.currenciesLoading && this.props.currencies) {
      return this.props.currencies.map((availableCurrency, index) => {
        return <option value={ availableCurrency.id } key={ index }>{ availableCurrency.label }</option>
      });
    }
  }

  render() {
    return (
      <div className="field-wrapper">
        <h3>{ I18n.t('worker.profile.form.billing.title') }</h3>

        <h4><span>{ I18n.t('worker.profile.form.billing.projectBillingTitle') }</span></h4>
        <div className={ `field ${this.getErrorClass('tarification')}` }>
          <label htmlFor="tarification">{ I18n.t('worker.profile.form.billing.field.tarification') }</label>
          <input
            id="tarification"
            type="text"
            value={ this.state.tarification }
            onChange={ this.handleChange }
            placeholder={ I18n.t('worker.profile.form.billing.field.tarificationPlaceholder') }
          />
          { this.showError('tarification') }
        </div>

        <h4><span>{ I18n.t('worker.profile.form.billing.consultationBillingTitle') }</span></h4>
        <label htmlFor="consultationRate">{ I18n.t('worker.profile.form.billing.field.consultationRate') }</label>

        <div className="fields-group">
          <div className={ `field ${this.getErrorClass('consultationRate')} half` }>
            <input
              id="consultationRate"
              type="number"
              value={ this.state.consultationRate }
              onChange={ this.handleChange }
              placeholder={ I18n.t('worker.profile.form.billing.field.consultationRatePlaceholder') }
            />
            { this.showError('consultationRate') }
          </div>
          <div className="select-group field half" style={{visibility: "hidden"}}>
            <select onChange={ this.handleChange } value={ this.state.currency } id="currency" >
              <option value="">{ I18n.t('worker.profile.form.currencies.field.placeholder') }</option>
              { this.buildOptions() }
            </select>
          </div>
        </div>

        <h4><span>{ I18n.t('worker.profile.form.billing.predefinedBudgetTitle') }</span></h4>
        <div className="fields-group radio-group">
          <div className="field-wrapper half">
            <input
              type="radio"
              id="predefinedBudgetYes"
              name="predefinedBudgetYes"
              value="yes"
              onChange={ this.handleRadioChange }
              checked={ !!this.state.acceptPredefinedBudget }
            />
            <label htmlFor="predefinedBudgetYes">{ I18n.t('worker.profile.form.billing.field.predefinedBudget.yes') }</label>
          </div>

          <div className="field-wrapper half">
            <input
              type="radio"
              id="predefinedBudgetNo"
              name="predefinedBudgetNo"
              value="no"
              onChange={ this.handleRadioChange }
              checked={ !this.state.acceptPredefinedBudget }
            />
            <label htmlFor="predefinedBudgetNo">{ I18n.t('worker.profile.form.billing.field.predefinedBudget.no') }</label>
          </div>
        </div>
      </div>
    );
  }
}

WorkerProfileBillingForm.propTypes = {
  billing: PropTypes.shape({
    tarification: PropTypes.string
  }),
  setBilling: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  loadCurrencies: PropTypes.func.isRequired,
  currenciesLoading: PropTypes.bool
};

WorkerProfileBillingForm.defaultProps = {
  billing: undefined
};

const mapStateToProps = state => ({
  currencies: state.data.currencies,
  currenciesLoading: state.data.currenciesLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCurrencies,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfileBillingForm);