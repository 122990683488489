import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { WorkerShape } from '../../model/Worker';

class WorkerHeaderAvailability extends Component {

  showCallToAction() {
    if (!this.props.workerProfile || !this.props.workerProfile.isValid()) {
      return <Link to="/talent/profile" className="btn transparent"><span>{ I18n.t('nav.worker.completeProfileLinkLabel') }</span></Link>;
    }
    return null;
  }

  render() {
    if (this.props.workerProfile) {
      return (
        <div className="grid-item worker-availability">
          <div>
            <div className="worker-status">{ I18n.t('nav.worker.currentStatus') }{ this.props.workerProfile.getAvailability() }</div>
            <div className="worker-rate">{ I18n.t('nav.worker.rate') }{ this.props.workerProfile.getTarification() }</div>
          </div>

          { this.showCallToAction() }
        </div>
      );
    }
    return null;
  }
}

WorkerHeaderAvailability.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  }),
  workerProfile: WorkerShape
};

WorkerHeaderAvailability.defaultProps = {
  user: undefined,
  workerProfile: undefined,
};

export default WorkerHeaderAvailability;
