import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import ProjectTile from '../components/ProjectTile';

import { CompanyShape } from '../../model/Company';
import { ProjectShape } from '../../model/Project';

import { loadAllCompanies } from '../../reducers/company';

class ProjectList extends Component {

  constructor(props) {
    super(props);

    this.displayProjects = this.displayProjects.bind(this);
  }

  componentDidMount() {
    this.props.loadAllCompanies();
  }

  filterProjects() {
    if (this.props.filters.length > 0) {
      return this.props.projects.filter(project => {
        return project.skills.reduce((found, evaluation) => {
          return found || this.props.filters.includes(evaluation.skillId);
        }, false);      
      });
    }
    return this.props.projects;
  }

  displayProjects() {
    if(this.filterProjects().length === 0) {
      return <p className="no-project-found"><span>{ I18n.t('project.list.noProjectFound') }</span></p>
    }
    else {
      return this.filterProjects()
        .map(project => {
          const company = this.props.companies.find(company => company.ownerId === project.ownerId);
          const isVisible = this.props.filterUnpublishedProjects ? project.status !== '0' : true;
          if (company && isVisible) {
            return <ProjectTile key={ project.id } project={ project } company={ company } />
          }
          return null;
        })
    }
  }

  render() {
    if (!this.props.companiesLoading && this.props.companies.length > 0) {
      return (
        <div className="grid">
          {this.displayProjects()}
        </div>
      );
    }
    return null;
  }
}

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(ProjectShape).isRequired,
  filters: PropTypes.arrayOf(PropTypes.number),
  companies: PropTypes.arrayOf(CompanyShape).isRequired,
  companiesLoading: PropTypes.bool.isRequired,
  loadAllCompanies: PropTypes.func.isRequired,
  filterUnpublishedProjects: PropTypes.bool
};

ProjectList.defaultProps = {
  projects: [],
  filters: [],
  companies: [],
  companiesLoading: true,
  filterUnpublishedProjects: true
};

const mapStateToProps = state => ({
  companies: state.company.companies,
  companiesLoading: state.company.companiesLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAllCompanies
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
