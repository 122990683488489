import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadSkills } from '../../reducers/data';

import SkillGroupsSelectorForm from './SkillGroupsSelectorForm';
import SkillsSelectorForm from './SkillsSelectorForm';

class SkillsForm extends Component {

  constructor(props) {
    super(props);
    this.setSkillGroupsSelected = this.setSkillGroupsSelected.bind(this);
    this.setSkillsSelected = this.setSkillsSelected.bind(this);
    this.displaySkillsSelectors = this.displaySkillsSelectors.bind(this);

    this.state = {
      skillGroupsSelected: [],
      skillsSelected: []
    };
  }

  componentWillMount() {
    this.props.loadSkills();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.skillsLoading && Array.isArray(nextProps.skills) && nextProps.skills.length > 0) {
      let skillGroupsSelected = this.state.skillGroupsSelected;
      const skillsSelected = nextProps.skills;
      if (skillGroupsSelected.length === 0) {
        skillGroupsSelected = nextProps.groupedSkills.filter(group => {
          return group.skills.filter(skill => skillsSelected.map(evaluation => evaluation.skillId).includes(skill.id)).length > 0;
        }).map(group => group.id);
      }

      this.setState({
        skillGroupsSelected,
        skillsSelected
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.skillGroupsSelected.length !== this.state.skillGroupsSelected.length) {
      const skillsSelected = this.filterSkillsSelected(this.state.skillGroupsSelected);
      this.props.setSkills(skillsSelected);
    }
  }

  getAvailableGroupedSkills() {
    return this.props.groupedSkills.filter(group => this.state.skillGroupsSelected.includes(group.id));
  }

  displaySkillGroupsSelector() {
    return (
      <div className="grid-item grid-item--1-2 skills-groups">
        <SkillGroupsSelectorForm
          groups={ this.props.groupedSkills }
          selectedIds={ this.state.skillGroupsSelected }
          setGroupsSelected={ this.setSkillGroupsSelected }
        />
      </div>
    );
  }

  displaySkillsSelectors() {
    return (
      <div className="grid-item grid-item--1-2 skills-subgroups">
        <SkillsSelectorForm
          groupedSkills={ this.getAvailableGroupedSkills() }
          selectedSkills={ this.state.skillsSelected }
          setSkillsSelected={ this.setSkillsSelected }
          withEvaluator={ this.props.withEvaluator }
          errors={ this.props.errors }
        />
      </div>
    );
  }

  displayErrors() {
    if (this.props.errors && this.props.errors.empty) {
      return (
        <p className="error-message">
          { this.props.errors.empty }
        </p>
      );
    }
  }

  setSkillGroupsSelected(groupIds) {
    this.setState(Object.assign({}, this.state, {
      skillGroupsSelected: groupIds,
      skillsSelected: this.filterSkillsSelected(groupIds)
    }));
  }

  setSkillsSelected(skillsSelected) {
    this.setState(Object.assign({}, this.state, { skillsSelected }));
    this.props.setSkills(skillsSelected);
  }

  filterSkillsSelected(selectedGroupIds) {
    const groups = this.props.groupedSkills.filter(group => selectedGroupIds.includes(group.id));
    return this.state.skillsSelected.filter(evaluation => {
      return groups.reduce((found, group) => {
        const subSkill = group.skills.find(skill => skill.id === evaluation.skillId);
        return found || !!subSkill;
      }, false);
    });
  }

  render() {
    if (this.props.skillsLoading) {
      return (
        <div className="skills-selection">
          <p>loading</p>
        </div>
      );
    } else {
      return (
        <div className="skills-selection">
          { this.displayErrors() }
          <div>
            <div className="grid">
              { this.displaySkillGroupsSelector() }
              { this.displaySkillsSelectors() }
            </div>
          </div>
        </div>
      );
    }
  }
}

SkillsForm.propTypes = {
  groupedSkills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  skillsLoading: PropTypes.bool.isRequired,
  loadSkills: PropTypes.func.isRequired,
  setSkills: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    empty: PropTypes.string
  }),
  withEvaluator: PropTypes.bool
};

SkillsForm.defaultProps = {
  groupedSkills: [],
  skills: [],
  skillsLoading: false,
  withEvaluator: false
};

const mapStateToProps = state => ({
  groupedSkills: state.data.groupedSkills,
  skillsLoading: state.data.skillsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSkills
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkillsForm);
