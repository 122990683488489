import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

class WorkerProfilePortfolioForm extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      portfolio: '',
      twitter: '',
      linkedin: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.portfolio) {
      this.setState(nextProps.portfolio);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setPortfolio(newState);
    this.setState(newState);
  }

  render() {
    return (
      <div className="form">
        <h3>{ I18n.t('worker.profile.form.portfolio.title') }</h3>

        <div className="fields-group wrap">
          <div className="field-wrapper half">
            <label htmlFor="portfolio">{ I18n.t('worker.profile.form.portfolio.field.portfolio') }</label>
            <div className="field">
              <input
                id="portfolio"
                type="text"
                value={ this.state.portfolio }
                onChange={ this.handleChange }
                placeholder={ I18n.t('worker.profile.form.portfolio.field.portfolioPlaceholder') }
              />
            </div>
          </div>

          <div className="field-wrapper half">
            <label htmlFor="twitter">{ I18n.t('worker.profile.form.portfolio.field.twitter') }</label>
            <div className="field">
              <input
                id="twitter"
                type="text"
                value={ this.state.twitter }
                onChange={ this.handleChange }
                placeholder={ I18n.t('worker.profile.form.portfolio.field.twitterPlaceholder') }
              />
            </div>
          </div>

          <div className="field-wrapper half">
            <label htmlFor="linkedin">{ I18n.t('worker.profile.form.portfolio.field.linkedin') }</label>
            <div className="field">
              <input
                id="linkedin"
                type="text"
                value={ this.state.linkedin }
                onChange={ this.handleChange }
                placeholder={ I18n.t('worker.profile.form.portfolio.field.linkedinPlaceholder') }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WorkerProfilePortfolioForm.propTypes = {
  portfolio: PropTypes.shape({}),
  setPortfolio: PropTypes.func.isRequired
};

WorkerProfilePortfolioForm.defaultProps = {
  portfolio: undefined
};

export default WorkerProfilePortfolioForm;
