import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CompanyNavigationBar extends Component {

  getItemClassName(itemPath) {
    if (itemPath === this.props.currentPath) {
      return 'active';
    }
  }

  render() {

    //Removed temporarily the Talents link in the company navigation bar, add it back if needed
    //<li className={ this.getItemClassName('/company/profiles') }>
    //<Link to="/company/profiles">Talents</Link>
    //</li>

    return (
      <div className="grid-item">
        <ul>
          <li className={ this.getItemClassName('/company') }>
            <Link to="/company">Tableau de bord</Link>  
          </li>
          <li className={ this.getItemClassName('/company/profile') }>
            <Link to="/company/profile">Mes informations</Link>
          </li>
        </ul>
      </div>
    );
  }
}

CompanyNavigationBar.propTypes = {
  currentPath: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  currentPath: state.routing.location.pathname
})

export default connect(mapStateToProps)(CompanyNavigationBar);
