import firebase from '../config/firebase';
import { history } from '../store'

const SET_USER = 'authentication/SET_USER'

const initialState = {
  firebaseUser: firebase.auth().currentUser,
  user: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        firebaseUser: action.payload.firebaseUser,
        user: action.payload.user
      });

    default:
      return state
  }
}

export function setUser() {
  return dispatch => {
    const firebaseUser = firebase.auth().currentUser;
    if (firebaseUser) {
      const database = firebase.database();
      database.ref('profiles/' + firebaseUser.uid).once('value').then(snapshot => {
        const user = Object.assign({}, snapshot.val(), { uid: firebaseUser.uid });
        return dispatch({
          type: SET_USER,
          payload: { user, firebaseUser }
        });
      });
    } else {
      return dispatch({
        type: SET_USER,
        payload: {
          user: undefined,
          firebaseUser: undefined
        }
      });
    }
  };
}

export function logout() {
  return dispatch => {
    firebase.auth().signOut().then(() => {
      history.push('/');
      return dispatch({
        type: SET_USER,
        payload: {
          user: undefined,
          firebaseUser: undefined
        }
      });
    }).catch(() => {
      history.push('/');
      return dispatch({
        type: SET_USER,
        payload: {
          user: undefined,
          firebaseUser: undefined
        }
      });
    });
  };
}