const company = {
  profile: {
    form: {
      title: 'Vos informations d\'entreprise',
      initialSubTitle: 'Complétez les informations sur votre entreprise',
      updatedSubTitle: 'Mettez à jour les informations sur votre entreprise',
      help: 'Pour finaliser la création de votre compte, complétez les informations liées à votre entreprise. Vous pourrez alors déposer votre première demande de projet !',
      details: {
        title: 'Coordonnées de votre entreprise',
        field: {
          name: 'Nom de l\'entreprise',
          namePlaceholder: 'Nom de votre entreprise',
          type: 'Type d\'entreprise',
          typePlaceholder: 'Choisir',
          typeProfit: 'A but lucratif (Inc-sarl -sas…)',
          typeNonProfit: 'A but non lucratif (Association-obnl…)',
          typeCooperative: 'Coopérative',
          typeIndependent: 'Indépendant',
          typePublicAdministration: 'Administration publique',
          logo: 'Logo',
          address: 'Adresse (numéro et rue)',
          addressPlaceholder: 'ex: 42, chemin des Talents M',
          postalCode: 'Code postal',
          postalCodePlaceholder: 'ex: A1A 1A1',
          city: 'Ville',
          cityPlaceholder: 'ex: Montréal',
          province: 'Province ou Région',
          provincePlaceholder: 'ex: Québec',
          country: 'Pays',
          countryPlaceholder: 'ex: Canada',
          size: 'Taille de l\'entreprise',
          sizePlaceholder: 'Choisir'
        }
      },
      activityFields: {
        title: 'Domaine d\'activité',
        placeholder: 'Choisir'
      },
      contact: {
        title: 'Information de contact',
        field: {
          email: 'Adresse courriel de contact',
          emailPlaceholder: 'ex: monadresse@monentreprise.com',
          phone: 'Téléphone',
          phonePlaceholder: 'ex: +1 555 555-5555',
          skype: 'Identifiant Skype (optionnel)',
          skypePlaceholder: 'votre identifiant skype',
          website: 'Site internet (optionnel)',
          websitePlaceholder: 'ex: https://www.monsite.com'
        }
      },
      saveButtonLabel: 'Enregister mes informations'
    }
  },
  errors: {
    name: {
      required: 'Ce champ est obligatoire'
    },
    type: {
      required: 'Ce champ est obligatoire'
    },
    size: {
      required: 'Ce champ est obligatoire'
    },
    address: {
      required: 'Ce champ est obligatoire'
    },
    postalCode: {
      required: 'Ce champ est obligatoire'
    },
    city: {
      required: 'Ce champ est obligatoire'
    },
    province: {
      required: 'Ce champ est obligatoire'
    },
    country: {
      required: 'Ce champ est obligatoire'
    },
    activityFields: {
      selectAtLeastOne: 'Choisir votre champ d\'activité principale'
    },
    email: {
      required: 'Ce champ est obligatoire'
    },
    phone: {
      required: 'Ce champ est obligatoire'
    }
  }
};

export default company;