import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import firebase from '../../config/firebase';

import { setUser } from '../../reducers/authentication';

const INVALID_EMAIL_ERROR_CODE = 'auth/invalid-email';
const INVALID_CREDENTIALS_ERROR_CODE = 'auth/wrong-password';

class FirebaseEmailLoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleChange(event, key) {
    this.setState({[key]: event.target.value});
  }

  handleLogin() {
    const { email, password } = this.state;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.setUser();
        this.props.redirectOnSuccess();
      })
      .catch(err => {
        this.handleError(err);
      });
  }

  handleError(error) {
    if (error.code === INVALID_EMAIL_ERROR_CODE) {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.email.invalid') }))
    } else if (error.code === INVALID_CREDENTIALS_ERROR_CODE) {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.credentials.invalid') }))
    } else {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.unknown') }))
    }
  }

  showError() {
    if (this.state.error) {
      return (
        <div className="field-wrapper error">
          <p>{ this.state.error }</p>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="form form--login">
        <div className="field-wrapper">
          <label>{ I18n.t('login.field.email') }</label>
          <div className="field">
            <input
              type="text"
              value={this.state.email}
              onChange={evt => this.handleChange(evt, 'email')}
              placeholder={ I18n.t('login.field.email') } />
          </div>
        </div>

        <div className="field-wrapper">
          <label>{ I18n.t('login.field.password') }</label>
          <div className="field">
            <input
              type="password"
              value={this.state.password}
              onChange={evt => this.handleChange(evt, 'password')}
              placeholder={ I18n.t('login.field.password') } />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="field">
            <button id="email-signup-button" name="email-signup-button" onClick={this.handleLogin} className="btn reverse">
              <span>{ I18n.t('login.buttonLabel') }</span>
            </button>
          </div>
        </div>
        { this.showError() }

      </div>
    );
  }
}

FirebaseEmailLoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
  redirectOnSuccess: PropTypes.func.isRequired
};

FirebaseEmailLoginForm.defaultProps = {
  email: '',
  password: ''
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setUser
}, dispatch)

export default connect(null, mapDispatchToProps)(FirebaseEmailLoginForm)
