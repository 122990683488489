import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import FileUploaderForm from './FileUploaderForm';

import firebase from '../../config/firebase';

import iconFile from '../../assets/img/icon-file.svg';

class ProjectDocumentsForm extends Component {

  displayExistingDocument(documentUrl) {
    const storage = firebase.storage();
    const fileName = storage.refFromURL(documentUrl).name;
    return (
      <div className="file-item">
        <img className="file-icon" src={ iconFile } alt=""/>
        <a key={ fileName } href={ documentUrl } download>{ fileName }</a>
      </div>
    );
  }

  render() {
    return (
      <div className="add-modele">
        { this.props.documents.map(this.displayExistingDocument) }
        <FileUploaderForm
          key={ new Date() }
          fileUploadedCallback={ this.props.fileUploadedCallback }
          uploadDirectory="documents"
          allowedFileType=".pdf,.doc,.docx"
          label={ I18n.t('project.form.documents.upload.addButtonLabel') }
          icon={ iconFile }
        />
      </div>
    );
  }
}

ProjectDocumentsForm.propTypes = {
  fileUploadedCallback: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ProjectDocumentsForm;
