import firebase from '../config/firebase';
import Company from '../model/Company';

const LOADED_ALL_COMPANY_PROFILE = 'company/LOADED_ALL_COMPANY_PROFILE';
const LOADING_ALL_COMPANY_PROFILE = 'company/LOADING_ALL_COMPANY_PROFILE';
const LOADED_COMPANY_PROFILE = 'company/LOADED_COMPANY_PROFILE';
const LOADING_COMPANY_PROFILE = 'company/LOADING_COMPANY_PROFILE';
const SAVING_COMPANY_PROFILE = 'company/SAVING_COMPANY_PROFILE';
const SAVED_COMPANY_PROFILE = 'company/SAVED_COMPANY_PROFILE';

const initialState = {
  companies: [],
  companiesLoading: false,
  profileLoading: true,
  profile: undefined,
  profileSaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADED_ALL_COMPANY_PROFILE:
      return Object.assign({}, state, {
        companiesLoading: false,
        companies: action.payload,
        profileSaved: false
      });

    case LOADING_ALL_COMPANY_PROFILE:
      return Object.assign({}, state, {
        companiesLoading: true
      });

    case LOADED_COMPANY_PROFILE:
      return Object.assign({}, state, {
        profileLoading: false,
        profile: action.payload,
        profileSaved: false
      });

    case LOADING_COMPANY_PROFILE:
      return Object.assign({}, state, {
        profileLoading: true
      });

    case SAVING_COMPANY_PROFILE:
      return Object.assign({}, state, {
        profileSaved: false
      });

    case SAVED_COMPANY_PROFILE:
      return Object.assign({}, state, {
        profile: action.payload,
        profileSaved: true
      });

    default:
      return state;
  }
}

export function loadAllCompanies() {
  return dispatch => {
    dispatch({ type: LOADING_ALL_COMPANY_PROFILE });

    const database = firebase.database();
    database.ref('companies/').once('value').then(snapshot => {
      const companies = snapshot.val();
      return dispatch({
        type: LOADED_ALL_COMPANY_PROFILE,
        payload: rawCompaniesToObjectList(companies)
      });
    });
  };
}

export function loadCompanyProfile(userId) {
  return dispatch => {
    dispatch({ type: LOADING_COMPANY_PROFILE });

    const database = firebase.database();
    database.ref('companies/' + userId).once('value').then(snapshot => {
      const profile = new Company(Object.assign({}, snapshot.val(), { ownerId: userId } ));
      return dispatch({
        type: LOADED_COMPANY_PROFILE,
        payload: profile
      });
    });
  };
}

export function saveCompanyProfile(profile) {
  return dispatch => {
    dispatch({ type: SAVING_COMPANY_PROFILE });

    const database = firebase.database();
    database.ref('companies/' + profile.ownerId).set(profile);

    return dispatch({
      type: SAVED_COMPANY_PROFILE,
      payload: profile
    });
  };
}

function rawCompaniesToObjectList(rawCompanies) {
  return Object.keys(rawCompanies).map(key => {
    return new Company(Object.assign({}, rawCompanies[key], { ownerId: key }))
  });
}
