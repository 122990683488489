import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import Worker, { WorkerShape } from '../../model/Worker';

import WorkerProfileFurtherInfoForm from './WorkerProfileFurtherInfoForm';
import WorkerProfileSummaryForm from './WorkerProfileSummaryForm';
import SkillsForm from './SkillsForm';
import ModalMessage from '../components/ModalMessage';

import { saveWorkerProfile, loadWorkerProfile } from '../../reducers/worker';

function buildState(state, currentProps, nextProps) {
  const newProfile = nextProps.profile;

  if (!currentProps.profileSaved && nextProps.profileSaved) {
    return Object.assign({}, state, {
      worker: newProfile,
      showSavedPopup: true
    });
  }

  return Object.assign({}, state, {
    worker: newProfile,
    showSavedPopup: false
  });
}

function getDefaultState(userId) {
  return {
    worker: new Worker({
      id: userId,
      skills: [],
      summary: undefined,
      furtherInfo: undefined
    }),
    errors: {}
  };
}

class WorkerProfileForm extends Component {

  constructor(props) {
    super(props);

    this.updateSummary = this.updateSummary.bind(this);
    this.updateSelectedSkills = this.updateSelectedSkills.bind(this);
    this.updateFurtherInfo = this.updateFurtherInfo.bind(this);
    this.submitProfile = this.submitProfile.bind(this);

    if (props.profile) {
      this.state = {
        worker: props.profile,
        errors: {}
      };
    } else {
      this.state = getDefaultState(props.user.uid);
    }
    this.state = Object.assign({}, this.state, { showSavedPopup: false });
  }

  componentWillMount() {
    this.props.loadWorkerProfile(this.props.user.uid);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(buildState(this.state, this.props, nextProps));
  }

  updateSummary(summary) {
    const worker = new Worker(Object.assign({}, this.state.worker, { summary }));
    this.setState(Object.assign({}, this.state, { worker }));
  }

  updateSelectedSkills(skills) {
    const worker = new Worker(Object.assign({}, this.state.worker, { skills }));
    this.setState(Object.assign({}, this.state, { worker }));
  }

  updateFurtherInfo(furtherInfo) {
    const worker = new Worker(Object.assign({}, this.state.worker, { furtherInfo }));
    this.setState(Object.assign({}, this.state, { worker }));
  }

  submitProfile() {
    const profile = this.state.worker;
    if (profile.isValid()) {
      this.props.saveWorkerProfile(profile, this.props.user.uid);
    } else {
      this.setState(Object.assign({}, this.state, { errors: profile.getErrors(), showSavedPopup: false }));
    }
  }

  showLoadingOverlay() {
    if (this.props.profileLoading) {
      return <div>Chargement des infos...</div>;
    }
  }

  getName() {
    if (this.state.worker && this.state.worker.summary && this.state.worker.summary.name) {
      return this.state.worker.summary.name;
    } else {
      return this.props.user.name;
    }
  }

  getSubtitleText = () => {
    const {profile} = this.props;
    if(profile){
      if (profile.isValid()){
        return I18n.t('worker.profile.form.updatedSubTitle');
      }
    }
    return I18n.t('worker.profile.form.initialSubTitle');
  }

  showSavedPopup() {
    if (this.state.showSavedPopup) {
      return <ModalMessage
        redirect="/talent"
        buttonLabel={ I18n.t('nav.worker.backToDashboardButtonLabel') }
        message={ I18n.t('worker.profile.form.savedMessage') }
      />;
    }
  }

  render() {
    return (
      <div>
        { this.showLoadingOverlay() }
        { this.showSavedPopup() }

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ this.getName() }{ this.getSubtitleText() }</span></h2>
                <p>{ I18n.t('worker.profile.form.subTitleText') }</p>
              </div>
            </div>
          </header>

          <div className="grid">
            <WorkerProfileSummaryForm setSummary={ this.updateSummary } summary={ this.state.worker.summary } errors={ this.state.errors.summary } />
          </div>
        </section>

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ I18n.t('worker.profile.form.skills.title') }</span></h2>
                <p>{ I18n.t('worker.profile.form.skills.subTitle') }</p>
              </div>
            </div>
          </header>

          <SkillsForm
            setSkills={ this.updateSelectedSkills }
            skills={ this.state.worker.skills }
            errors={ this.state.errors.skills }
            withEvaluator={ true }
          />
        </section>

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ I18n.t('worker.profile.form.furtherInfoSectionTitle') }</span></h2>
              </div>
            </div>
          </header>

          <div className="grid">
            <WorkerProfileFurtherInfoForm setFurtherInfo={ this.updateFurtherInfo } furtherInfo={ this.state.worker.furtherInfo } errors={ this.state.errors.furtherInfo } />
          </div>
        </section>

        <section className="section section--profile">
          <div className="grid">
            <button onClick={ this.submitProfile }>
              <span>{ I18n.t('worker.profile.form.saveButtonLabel') }</span>
            </button>
          </div>
        </section>
      </div>
    );
  }
}

WorkerProfileForm.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string
  }),
  profile: WorkerShape,
  profileLoading: PropTypes.bool.isRequired,
  profileSaved: PropTypes.bool.isRequired,
  loadWorkerProfile: PropTypes.func.isRequired,
  saveWorkerProfile: PropTypes.func.isRequired
};

WorkerProfileForm.defaultProps = {
  user: undefined,
  profile: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  profile: state.worker.profile,
  profileLoading: state.worker.profileLoading,
  profileSaved: state.worker.profileSaved
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadWorkerProfile,
  saveWorkerProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfileForm);
