import React, { Component } from 'react';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import PropTypes from 'prop-types';

class FileUploaderForm extends Component {

  constructor(props) {
    super(props);

    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.buildFileName = this.buildFileName.bind(this);

    this.state = {
      file: '',
      isUploading: false,
      progress: 0,
      fileURL: ''
    };
  }

  handleUploadStart() {
    this.setState({
      isUploading: true,
      progress: 0
    });
  }

  handleProgress(progress) {
    this.setState({progress});
  }

  handleUploadError() {
    this.setState({isUploading: false});
  }

  handleUploadSuccess(filename) {
    this.setState({file: filename, progress: 100, isUploading: false});
    firebase.storage().ref(this.props.uploadDirectory).child(filename).getDownloadURL().then(url => {
      this.props.fileUploadedCallback(url);
      this.setState({ fileURL: url });
    });
  }

  buildFileName(file) {
    const date = new Date();
    return `${date.getTime()}-${file.name}`;
  }

  showProgress() {
    if (this.state.isUploading) {
      return <p>Progress: {this.state.progress}</p>;
    }
    return;
  }

  showIcon() {
    if (this.props.icon) {
      return <img className="file-icon" src={ this.props.icon } alt=""/>
    }
    return;
  }

  render() {
    return (
      <div>
        <form>
          { this.showIcon() }
          <label htmlFor="file">{ this.props.label }</label>
          <FileUploader
            accept={ this.props.allowedFileType }
            name="file"
            filename={ this.buildFileName }
            storageRef={firebase.storage().ref(this.props.uploadDirectory)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
          />
          { this.showProgress() }
        </form>
      </div>
    );
  }
}

FileUploaderForm.propTypes = {
  fileUploadedCallback: PropTypes.func.isRequired,
  uploadDirectory: PropTypes.string.isRequired,
  allowedFileType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default FileUploaderForm;
