import PropTypes from 'prop-types';
import ProjectSummary, { ProjectSummaryShape } from './ProjectSummary';
import Consultation, { ConsultationShape } from './Consultation';
import uuidv1 from 'uuid/v1';
import { I18n } from 'react-redux-i18n';
import config from '../config/config';

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class Project {

  constructor(projectInfo) {
    this.id = projectInfo.id || uuidv1();
    this.createdDate = projectInfo.createdDate || new Date().getTime();
    this.ownerId = projectInfo.ownerId;
    this.skills = projectInfo.skills || [];
    this.consultation = new Consultation(projectInfo.consultation || {});
    this.summary = new ProjectSummary(projectInfo.summary);
    this.documents = projectInfo.documents || [];
    this.status = projectInfo.status;
  }

  getReadableCreatedDate() {
    const date = new Date(this.createdDate);
    return date.toLocaleDateString(config.dateLocale, config.dateFormatOption);
  }

  equals(other) {
    return (
      !!other &&
      this.status === other.status &&
      this.summary.equals(other.summary)
    );
  }

  isValid() {
    return (
      notEmpty(this.id) &&
      notEmpty(this.ownerId) &&
      notEmpty(this.status) &&
      this.summary.isValid() &&
      Array.isArray(this.skills) &&
      Array.isArray(this.documents) &&
      this.skills.length > 0
    );
  }

  getErrors() {
    const errors = {
      summary: this.summary.getErrors()
    };

    if (!notEmpty(this.status)) {
      errors.status = I18n.t('project.errors.status.required');
    }

    if (this.skills.length < 1) {
      errors.skills = I18n.t('project.errors.skills.selectAtLeastOne');
    }

    return errors;
  }
}

export default Project;

export const ProjectShape = PropTypes.shape({
  summary: ProjectSummaryShape,
  status: PropTypes.string,
  skills: PropTypes.array,
  consultation: ConsultationShape
});