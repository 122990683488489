import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WorkerHeaderProfile from '../components/WorkerHeaderProfile';
import WorkerHeaderAvailability from '../components/WorkerHeaderAvailability';
import WorkerHeaderSwitchProfile from '../components/WorkerHeaderSwitchProfile';

import { WorkerShape } from '../../model/Worker';

class WorkerHeaderBar extends Component {

  render() {
    return (
      <div className="grid">
        <WorkerHeaderProfile user={ this.props.user } workerProfile={ this.props.workerProfile } />
        <WorkerHeaderAvailability user={ this.props.user } workerProfile={ this.props.workerProfile } />
        <WorkerHeaderSwitchProfile user={ this.props.user } />
      </div>
    );
  }
}

WorkerHeaderBar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  }),
  workerProfile: WorkerShape
};

WorkerHeaderBar.defaultProps = {
  user: undefined,
  workerProfile: undefined
};

export default WorkerHeaderBar;
