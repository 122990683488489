import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { WorkerShape } from '../../model/Worker';

const MAX_SKILLS_DISPLAYED = 5;

class WorkerProfileTile extends Component {

  constructor(props) {
    super(props);
    this.getSkillLabel = this.getSkillLabel.bind(this);
  }

  getLinkToProfilePage() {
    return `/${this.props.location.pathname.split('/')[1]}/profile/${this.props.profile.id}`;
  }

  getSkillLabel(skillId) {
    const skillGroup = this.props.groupedSkills.find(group => group.skills.map(skill => skill.id).includes(skillId));
    if (skillGroup) {
      return <span key={skillId}>{ skillGroup.skills.find(skill => skill.id === skillId).label }</span>;
    }
    return;
  }

  showProfilePicture() {
    if (this.props.profile && this.props.profile.summary && this.props.profile.summary.picture) {
      return <img className="avatar" src={ this.props.profile.summary.picture } alt="" />
    }
    return;
  }

  showBestSkills() {
    if (this.props.groupedSkills.length > 0) {
      return this.props.profile.skills
          .sort((a, b) => a.score < b.score)
          .map(evaluation => evaluation.skillId)
          .slice(0, MAX_SKILLS_DISPLAYED)
          .map(this.getSkillLabel);
    }
    return;
  }

  render() {
    const profile = this.props.profile;
    return (
      <div className="grid-item grid-item--1-3">
        <div className="tile tile--project with-talent">
          <div className="content">
            { this.showProfilePicture() }
            <h2 className="title">{ profile.summary.name }</h2>
            <p className="job">{ profile.summary.title }</p>
            <p className="city"><i className="fa fa-map-pin"></i>{ profile.summary.city }</p>
            <p className="best-skill">{ this.showBestSkills() }</p>
            <Link to={ this.getLinkToProfilePage() } className="btn small reverse"><span>{ I18n.t('worker.tile.seeProfileButtonLabel') }</span></Link>
          </div>
        </div>
      </div>
    );
  }
}

WorkerProfileTile.propTypes = {
  profile: WorkerShape.isRequired,
  skillGroups: PropTypes.array.isRequired,
  location: PropTypes.object,
  groupedSkills: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  location: state.routing.location,
  groupedSkills: state.data.groupedSkills
})

export default connect(mapStateToProps)(WorkerProfileTile);
