import React, { Component } from 'react'
import PropTypes from 'prop-types';

class SkillGroupsSelector extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  handleChange(groupId) {
    const groups = this.props.selectedIds;
    if (groups.includes(groupId)) {
      this.props.setGroupsSelected(groups.filter(id => {
        return id !== groupId;
      }));
    } else {
      groups.push(groupId);
      this.props.setGroupsSelected(groups);
    }
  }

  displayGroups() {
    return this.props.groups.map(group => {
      const id = group.id;
      return (
        <div key={ id } className="btn-skill">
          <input
            id={ `group-${id}` }
            type="checkbox"
            value={ id }
            checked={ this.props.selectedIds.includes(id) }
            onChange={ () => this.handleChange(id) } />
          <label htmlFor={ `group-${id}` }>
            { group.label }
          </label>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        { this.displayGroups() }
      </div>
    );
  }
}

SkillGroupsSelector.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setGroupsSelected: PropTypes.func.isRequired
};

export default SkillGroupsSelector;
