const login = {
  title: 'Connectez-vous pour continuer',
  socialLoginSectionTitle: 'ou connectez-vous avec',
  signup: {
    label: 'Vous n\'avez pas encore de compte?',
    linkLabel: 'Inscrivez-vous!'
  },
  forgotPassword: {
    title: 'Réinitialise ton mot de passe',
    linkLabel: 'Mot de passe oublié?',
    field: {
      email: 'Adresse courriel'
    },
    buttonLabel: 'Réinitialiser mon mot de passe',
    error: {
      email: {
        required: 'L\'adresse courriel est obligatoire'
      }
    }
  },
  field: {
    email: 'Adresse courriel',
    password: 'Mot de passe'
  },
  buttonLabel: 'Me connecter',
  backToLogin: 'Retour à la page de connexion',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  error: {
    email: {
      invalid: 'L\'adresse courriel saisie est invalide',
      notFound: 'L\'adresse courriel saisie n\'est associée à aucun compte'
    },
    credentials: {
      invalid: 'l\'adresse ou le mot de passe est invalide'
    },
    unknown: 'Une erreur est survenue lors de la tentative de connexion'
  },
  reset: {
    emailSent: 'Un courriel vous a été envoyé avec les instructions pour réinitialiser votre mot de passe'
  }
};

export default login;