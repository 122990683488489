const nav = {
  common: {
    logout: 'Déconnexion'
  },
  company: {
    completeProfileLinkLabel: 'Compléter mon profil',
    workOnProjectLabel: 'Vous voulez travailler sur un projet ?',
    switchProfileButtonLabel: 'Basculer sur mon profil Talent',
    createWorkerProfileButtonLabel: 'Créer mon profil Ressource',
    projectCountLabel: 'projets',
    createProjectButtonLabel: 'Créer un nouveau projet',
    backToDashboardButtonLabel: 'Retourner sur le tableau de bord'
  },
  worker: {
    seePublicProfileLinkLabel: 'Voir mon profil public',
    completeProfileLinkLabel: 'Compléter mon profil',
    currentStatus: 'Statut : ',
    rate: 'Tarif : ',
    publishProjectLabel: 'Vous voulez publier un projet ?',
    switchProfileButtonLabel: 'Basculer sur mon profil demandeur',
    createCompanyProfileButtonLabel: 'Créer mon profil demandeur',
    backToDashboardButtonLabel: 'Retourner sur le tableau de bord'
  }
};

export default nav;