import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import WorkerProfileList from './WorkerProfileList';
import SkillsFilter from '../components/SkillsFilter';

import { loadAllWorkers } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';

class WorkerProfileBrowser extends Component {

  constructor(props) {
    super(props);

    this.updateFilters = this.updateFilters.bind(this);
    this.displayWorkerProfileList = this.displayWorkerProfileList.bind(this);

    this.state = {
      filters: []
    };
  }

  componentDidMount() {
    this.props.loadAllWorkers();
  }

  updateFilters(filters) {
    this.setState(Object.assign({}, this.state, { filters }));
  }

  displayWorkerProfileList() {
    const {filters} = this.state;
    if (filters.length !== 0) {
      return (
        <div className="worker-list">
          <h2><span>{ I18n.t('project.tile.title') }</span></h2>
          <WorkerProfileList profiles={ this.props.profiles.filter(profile => profile.isValid()) } filters={ this.state.filters } />
        </div>
      )
    }
  }

  render() {
    if (this.props.profiles.length > 0) {
      return (
        <div className="grid">
          <h2><span>{ I18n.t('filters.workerTitle') }</span></h2>
          <p className="description"><span>{ I18n.t('filters.description') }</span></p>
          <SkillsFilter selectedSkills={ this.state.filters } updateFilters={ this.updateFilters } />
          {this.displayWorkerProfileList()}
        </div>
      );
    }
    return <p>{ I18n.t('project.list.noProjectToShow') }</p>
  }
}

WorkerProfileBrowser.propTypes = {
  loadAllWorkers: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(WorkerShape),
  profilesLoading: PropTypes.bool
};

WorkerProfileBrowser.defaultProps = {
  profiles: [],
  profilesLoading: true
};

const mapStateToProps = state => ({
  profiles: state.worker.profiles,
  profilesLoading: state.worker.profilesLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAllWorkers
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfileBrowser);
