import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { WorkerShape } from '../../model/Worker';

class WorkerHeaderProfile extends Component {

  showCallToAction = () => {
    const { workerProfile, user } = this.props;
    if (workerProfile && workerProfile.isValid()) {
      return (
        <Link to={ `/talent/profile/${user.uid}` } className="btn transparent">
          <span>{ I18n.t('nav.worker.seePublicProfileLinkLabel') }</span>
        </Link>
      );
    } else {
      return (
        <Link to="/talent/profile" className="btn transparent">
          <span>{ I18n.t('nav.worker.completeProfileLinkLabel') }</span>
        </Link>
      );
    }
  }

  showName = () => {
    const { workerProfile } = this.props;
    if (workerProfile && workerProfile.isValid()) {
      return (
          <p>{ workerProfile.summary.name }</p>
      );
    }
    return null;
  }

  showProfilePicture = () => {
    const { workerProfile } = this.props;
    if (workerProfile) {
      return <img className="worker-avatar" src={ workerProfile.summary.picture } alt="" />
    }
    return <div className="worker-avatar"></div>
  }

  render() {
    return (
      <div className="grid-item worker-information">
        { this.showProfilePicture() }
        <div className="worker-name">
          { this.showName() }
          { this.showCallToAction() }
        </div>
      </div>
    );
  }
}

WorkerHeaderProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    uid: PropTypes.string
  }),
  workerProfile: WorkerShape
};

WorkerHeaderProfile.defaultProps = {
  user: undefined,
  workerProfile: undefined
};

export default WorkerHeaderProfile;
