import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';

class ModalMessage extends Component {

  render() {

    return (
      <div className="modal-message">
        <ScrollToTopOnMount />
        <div className="modal-content">
          <p>
            { this.props.message }
            <span>{ I18n.t('modal.helper') }</span>
          </p>
          <Link
            className="btn modal"
            to={ this.props.redirect }>
            { this.props.buttonLabel }
          </Link>
        </div>
      </div>
    );
  }
}

ModalMessage.propTypes = {
  redirect: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ModalMessage;
