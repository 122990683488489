import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import WorkerProfileTile from '../components/WorkerProfileTile';

import { WorkerShape } from '../../model/Worker';

import { loadSkills } from '../../reducers/data';

class WorkerProfileList extends Component {

  constructor(props) {
    super(props);

    this.displayProfiles = this.displayProfiles.bind(this);
    this.displayFoundProfiles = this.displayProfiles.bind(this);
  }

  componentDidMount() {
    this.props.loadSkills();
  }

  filterProfiles() {
    if (this.props.filters.length > 0) {
      return this.props.profiles.filter(profile => {
        return profile.skills.reduce((found, evaluation) => {
          return found || this.props.filters.includes(evaluation.skillId);
        }, false);      
      });
    }
    return this.props.profiles;
  }

  displayProfiles() {
    if(this.filterProfiles().length === 0) {
      return <p className="no-worker-found"><span>{ I18n.t('filters.noWorkerFound') }</span></p>
    }
    else {
      return this.filterProfiles().map(profile => {
        return (
          <WorkerProfileTile key={ profile.id } profile={ profile } skillGroups={ this.props.skillGroups } />
       );
      })
    }
  }

  render() {
    if (!this.props.skillsLoading && this.props.profiles.length > 0) {
      return (
        <div className="grid">
          { this.displayProfiles() }
        </div>
      );
    }
    return null;
  }
}

WorkerProfileList.propTypes = {
  profiles: PropTypes.arrayOf(WorkerShape).isRequired,
  filters: PropTypes.arrayOf(PropTypes.number),
  loadSkills: PropTypes.func.isRequired,
  skillGroups: PropTypes.array,
  skillsLoading: PropTypes.bool
};

WorkerProfileList.defaultProps = {
  filters: [],
  skillGroups: [],
  skillsLoading: false
};

const mapStateToProps = state => ({
  skillGroups: state.data.groupedSkills,
  skillsLoading: state.data.skillsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSkills
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfileList);
