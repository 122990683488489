import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class CompanyDetails {

  constructor(details) {
    Object.assign(this, details);
    this.logo = this.logo || '';
  }

  equals(other) {
    return (
      this.name === other.name &&
      this.type === other.type &&
      this.size === other.size &&
      this.address === other.address &&
      this.postalCode === other.postalCode &&
      this.city === other.city &&
      this.province === other.province &&
      this.country === other.country &&
      this.logo === other.logo
    );
  }

  areValid() {
    return (
      notEmpty(this.name) &&
      notEmpty(this.type) &&
      notEmpty(this.size) &&
      notEmpty(this.address) &&
      notEmpty(this.postalCode) &&
      notEmpty(this.city) &&
      notEmpty(this.province) &&
      notEmpty(this.country)
    );
  }

  getErrors() {
    const errors = {};

    if (!notEmpty(this.name)) {
      errors.name = I18n.t('company.errors.name.required');
    }

    if (!notEmpty(this.type)) {
      errors.type = I18n.t('company.errors.type.required');
    }

    if (!notEmpty(this.size)) {
      errors.size = I18n.t('company.errors.size.required');
    }

    if (!notEmpty(this.address)) {
      errors.address = I18n.t('company.errors.address.required');
    }

    if (!notEmpty(this.postalCode)) {
      errors.postalCode = I18n.t('company.errors.postalCode.required');
    }

    if (!notEmpty(this.city)) {
      errors.city = I18n.t('company.errors.city.required');
    }

    if (!notEmpty(this.province)) {
      errors.province = I18n.t('company.errors.province.required');
    }
    
    if (!notEmpty(this.country)) {
      errors.country = I18n.t('company.errors.country.required');
    }

    return errors;
  }
}

export default CompanyDetails;

export const CompanyDetailsShape = PropTypes.shape({
  name: PropTypes.String,
  type: PropTypes.String,
  size: PropTypes.String,
  address: PropTypes.String,
  postalCode: PropTypes.String,
  city: PropTypes.String,
  province: PropTypes.String,
  country: PropTypes.String,
  logo: PropTypes.String
});