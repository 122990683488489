import PropTypes from 'prop-types';

class Consultation {

  constructor(consultation) {
    this.needConsultation = consultation.needConsultation || false;
    this.consultationId = consultation.consultationId || '';
  }
}

export default Consultation;

export const ConsultationShape = PropTypes.shape({
  needConsultation: PropTypes.bool,
  consultationId: PropTypes.string,
});