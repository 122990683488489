import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { loadCompanyProjects } from '../../reducers/project';

import { ProjectShape } from '../../model/Project';
import { CompanyShape } from '../../model/Company';

class CompanyHeaderProjectStatus extends Component {

  componentDidMount() {
    this.props.loadCompanyProjects(this.props.user.uid);
  }

  getProjectCount() {
    if (!this.props.companyProjectsLoading) {
      return <p><em>{ this.props.companyProjects.length } { I18n.t('nav.company.projectCountLabel') }</em></p>;
    }
    return null;
  }

  showCreateProjectButton() {
    if (this.props.companyProfile && this.props.companyProfile.isValid()) {
      return <Link to="/company/project" className="btn transparent"><span>{ I18n.t('nav.company.createProjectButtonLabel') }</span></Link>;
    }
  }

  render() {
    return (
      <div className="grid-item worker-availability">
        <div>
          { this.getProjectCount() }
          { this.showCreateProjectButton() }
        </div>
      </div>
    );
  }
}

CompanyHeaderProjectStatus.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  companyProfile: CompanyShape,
  loadCompanyProjects: PropTypes.func.isRequired,
  companyProjects: PropTypes.arrayOf(ProjectShape),
  companyProjectsLoading: PropTypes.bool
};

CompanyHeaderProjectStatus.defaultProps = {
  companyProfile: undefined,
  user: undefined
};

const mapStateToProps = state => ({
  companyProjects: state.project.companyProjects,
  companyProjectsLoading: state.project.companyProjectsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanyProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyHeaderProjectStatus);
