import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { CompanyShape } from '../../model/Company';

class CompanyHeaderProfile extends Component {

  showCallToAction() {
    if (this.props.companyProfile && this.props.companyProfile.isValid()) {
      return <p>{ this.props.companyProfile.details.name }</p>;
    } else {
      return <Link to="/company/profile" className="btn transparent"><span>{ I18n.t('nav.company.completeProfileLinkLabel') }</span></Link>;
    }
  }

  showLogo() {
    if (this.props.companyProfile) {
      return <img className="worker-avatar" src={ this.props.companyProfile.details.logo } alt="" />
    }
    return <div className="worker-avatar"></div>
  }

  render() {
    return (
      <div className="grid-item worker-information">
        { this.showLogo() }
        <div className="worker-name">
          <p>{ this.props.user.name }</p>
          { this.showCallToAction() }
        </div>
      </div>
    );
  }
}

CompanyHeaderProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  }),
  companyProfile: CompanyShape
};

CompanyHeaderProfile.defaultProps = {
  user: undefined,
  companyProfile: undefined
}

export default CompanyHeaderProfile;
