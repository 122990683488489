import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import firebase from '../../config/firebase';

const INVALID_EMAIL_ERROR_CODE = 'auth/invalid-email';
const EMAIL_NOT_FOUND_CODE = 'auth/user-not-found';

class FirebaseForgotPasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleChange(event) {
    this.setState(Object.assign({}, this.state, { email: event.target.value }));
  }

  isFormValid() {
    let valid = true;

    if (this.state.email === '') {
      valid = false;
      this.setState(Object.assign({}, this.state, {
        error: I18n.t('login.forgotPassword.error.email.required')
      }));
    }

    return valid;
  }

  handleError(error) {
    if (error.code === INVALID_EMAIL_ERROR_CODE) {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.email.invalid') }))
    } else if (error.code === EMAIL_NOT_FOUND_CODE) {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.email.notFound') }))
    } else {
      this.setState(Object.assign({}, this.state, { error: I18n.t('login.error.unknown') }))
    }
  }

  handleSubmit() {
    if (this.isFormValid()) {
      const { email } = this.state;
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          this.setState(Object.assign({}, this.state, { success: I18n.t('login.reset.emailSent') }))
        })
        .catch(this.handleError);
    }
  }

  showError() {
    if (this.state.error && this.state.error) {
      return (
        <div className="field-wrapper error">
          <p>{ this.state.error }</p>
        </div>
      );
    }
  }

  showSuccessMessage() {
    if (this.state.success && this.state.success) {
      return (
        <div className="field-wrapper">
          <p>{ this.state.success }</p>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="form form--login">
        <div className="field-wrapper">
          <label>{ I18n.t('login.forgotPassword.field.email') }</label>
          <div className="field">
            <input
              type="text"
              value={ this.state.email }
              onChange={ this.handleChange }
              placeholder={ I18n.t('login.forgotPassword.field.email') } />
          </div>
        </div>

        { this.showError() }
        { this.showSuccessMessage() }
        <div className="field-wrapper">
          <div className="field">
            <button id="forgot-password-button" name="forgot-password-button" onClick={ this.handleSubmit } className="btn reverse">
              <span>{ I18n.t('login.forgotPassword.buttonLabel') }</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FirebaseForgotPasswordForm.propTypes = {
  email: PropTypes.string.isRequired
};

FirebaseForgotPasswordForm.defaultProps = {
  email: ''
};


export default FirebaseForgotPasswordForm
