import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { loadAllCompanies } from '../../reducers/company';
import { loadAllProjects } from '../../reducers/project';
import { loadSkills } from '../../reducers/data';

import { ProjectShape } from '../../model/Project';
import { CompanyShape } from '../../model/Company';

import firebase from '../../config/firebase';

import iconProject from '../../assets/img/icon-project.svg';
import iconDownload from '../../assets/img/icon-download.svg';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';

function filterGroupsBySkillsIds(skillIds, groups) {
  return groups.filter(group =>
    !!group.skills
      .map(skill => skill.id)
      .find(skillId => skillIds.includes(skillId))
  );
}

class ProjectDetails extends Component {

  componentDidMount() {
    this.props.loadSkills();
    this.props.loadAllCompanies();
    this.props.loadAllProjects();
  }

  findProject() {
    return this.props.projects.find(project => project.id === this.props.match.params.projectId);
  }

  findProjectCompany(project) {
    return this.props.companies.find(company => company.ownerId === project.ownerId);
  }

  displayProjectDocuments(project) {
    const storage = firebase.storage();
    return project.documents.map(documentUrl => {
      const fileName = storage.refFromURL(documentUrl).name;
      return <a key={ fileName } href={ documentUrl } className="modele-item" download>
        <p className="modele-name">{ fileName }</p>
        <img src={iconDownload} alt=""/>
      </a>;
    });
  }

  showSkills(project) {
    if (!this.props.skillsLoading) {
      const skillsIds = project.skills.map(evaluation => evaluation.skillId);
      const groups = filterGroupsBySkillsIds(skillsIds, this.props.groupedSkills);

      return groups.map(group => {
        return (
          <li className="skill-items" key={ group.id }>
            <ul>
              <p className="skill-tile-title">{ group.label }</p>
              {
                group.skills
                  .filter(skill => skillsIds.includes(skill.id))
                  .map(skill => <li key={ `${group.id}-${skill.id}` }><i className="fa fa-square" aria-hidden="true"></i>{ skill.label }</li>)
              }
            </ul>
          </li>
        );
      });
    }
    return null;
  }

  render() {
    if (this.props.projects.length > 0) {
      const project = this.findProject();
      if (project) {
        const company = this.findProjectCompany(project);
        if (company) {
          return (
            <div>
              <ScrollToTopOnMount />
              <div className="grid project-details">
                <div className="grid-item">
                  <div className="has-bg center">
                    <img className="project-image" src={iconProject} alt="project logo" />
                    <h3 className="project-title">{ project.summary.title }</h3>
                    <p className="company-name">{ I18n.t('project.details.publishedByLabel') } { company.details.name } { I18n.t('project.details.publishedDateLabel') } { project.getReadableCreatedDate() }</p>
                  </div>
                </div>

                <div className="grid project-about">

                  <div className="grid-item half about">
                    <p className="user-name">{ I18n.t('project.details.aboutProjectLabel') } { project.summary.title }</p>
                    <p>{ project.summary.description }</p>
                    <p className="project-budget">{ I18n.t('project.details.budgetLabel') } { project.summary.budget }</p>
                  </div>

                  <div className="grid-item half aside-skills">
                    <div>
                      <ul className="skills-list-container">
                        { this.showSkills(project) }
                      </ul>
                    </div>
                  </div>

                  <div className="grid-item">
                    <h3 className="half-title">{ I18n.t('project.details.documentsTitle') }</h3>
                    <ul className="modeles-list">
                      { this.displayProjectDocuments(project) }
                    </ul>
                  </div>

                  <div className="grid-item">
                    <div className="has-bg center">
                      <p className="call-to-action">{ I18n.t('project.details.callToAction') } <a href={"mailto:"+I18n.t('project.details.contact')}>{ I18n.t('project.details.contact') }</a></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          );
        }
      }
    }
    return <p>{ I18n.t('project.details.notFound') }</p>;
  }
}

ProjectDetails.propTypes = {
  companies: PropTypes.arrayOf(CompanyShape),
  projects: PropTypes.arrayOf(ProjectShape),
  skillsLoading: PropTypes.bool,
  loadSkills: PropTypes.func.isRequired,
  loadAllCompanies: PropTypes.func.isRequired,
  loadAllProjects: PropTypes.func.isRequired,
  groupedSkills: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string
    })
  })
};

ProjectDetails.defaultProps = {
  companies: [],
  projects: [],
  skillsLoading: true
};

const mapStateToProps = state => ({
  companies: state.company.companies,
  projects: state.project.projects,
  groupedSkills: state.data.groupedSkills,
  skillsLoading: state.data.skillsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSkills,
  loadAllCompanies,
  loadAllProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
