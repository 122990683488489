import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

class WorkerProfileAvailabilityForm extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.state = {
      weeklyAvailability: '',
      currentStatus: '',
      currentStatusEndDate: '',
      onSite: false,
      remote: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.availability && nextProps.availability) {
      this.setState(nextProps.availability);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setAvailability(newState);
    this.setState(newState);
  }

  handleLocationChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: !this.state[event.target.id] });
    this.props.setAvailability(newState);
    this.setState(newState);
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p>{ this.props.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div className="field-wrapper">
        <h3>{ I18n.t('worker.profile.form.availability.title') }</h3>
        <h4><span>{ I18n.t('worker.profile.form.availability.field.currentStatus') }</span></h4>

        <div className={ `field select-group select-${this.getErrorClass('currentStatus')} availability` }>
          <select onChange={ this.handleChange } value={ this.state.currentStatus } id="currentStatus" >
            <option value="">{ I18n.t('worker.profile.form.availability.field.currentStatusPlaceholder') }</option>
            <option value="available">{ I18n.t('worker.availability.available') }</option>
            <option value="occupied">{ I18n.t('worker.availability.occupied') }</option>
          </select>
          { this.showError('currentStatus') }
        </div>

        <div className="field half">
          <label htmlFor="currentStatusEndDate">{ I18n.t('worker.profile.form.availability.field.currentStatusEndDate') }</label>
          <input
            id="currentStatusEndDate"
            type="text"
            value={ this.state.currentStatusEndDate }
            onChange={ this.handleChange }
            placeholder={ I18n.t('worker.profile.form.availability.field.currentStatusEndDatePlaceholder') }
          />
        </div>

        <h4><span>{ I18n.t('worker.profile.form.availability.weeklyAvailabilityTitle') }</span></h4>
        <div className={ `field half ${this.getErrorClass('weeklyAvailability')}` }>
          <label htmlFor="weeklyAvailability">{ I18n.t('worker.profile.form.availability.field.weeklyAvailability') }</label>
          <input
            id="weeklyAvailability"
            type="text"
            value={ this.state.weeklyAvailability }
            onChange={ this.handleChange }
            placeholder={ I18n.t('worker.profile.form.availability.field.weeklyAvailabilityPlaceholder') }
          />
          { this.showError('weeklyAvailability') }
        </div>

        <h4><span>{ I18n.t('worker.profile.form.availability.locationTitle') }</span></h4>

        <div className="fields-group checkbox-group">
          <div className="field-wrapper half">
            <input
              id="onSite"
              type="checkbox"
              value="onSite"
              checked={ !!this.state.onSite }
              onChange={ this.handleLocationChange }
            />
            <label htmlFor="onSite">{ I18n.t('worker.profile.form.availability.field.onSite') }</label>
          </div>
          <div className="field-wrapper half">
            <input
              id="remote"
              type="checkbox"
              value="remote"
              checked={ !!this.state.remote }
              onChange={ this.handleLocationChange }
            />
            <label htmlFor="remote">{ I18n.t('worker.profile.form.availability.field.remote') }</label>
          </div>
        </div>
      </div>
    );
  }
}

WorkerProfileAvailabilityForm.propTypes = {
  setAvailability: PropTypes.func.isRequired,
  availability: PropTypes.shape({
    weeklyAvailability: PropTypes.string,
    currentStatus: PropTypes.string,
    currentStatusEndDate: PropTypes.string
  }),
  errors: PropTypes.shape({})
};

WorkerProfileAvailabilityForm.defaultProps = {
  availability: undefined
};

export default WorkerProfileAvailabilityForm;
