import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import FirebaseEmailSignupForm from '../forms/FirebaseEmailSignupForm';

import logoWhite from '../../assets/img/logo-white.svg';

class EmailSignUp extends Component {

  render() {
    return (
      <div className="page page--register">
        <section className="section section--splash">
          <img src={logoWhite} alt="Les Talents M." />
        </section>

        <section className="section section--register">
          <div>
            <h1>{ I18n.t('signup.email.title') }</h1>
            <FirebaseEmailSignupForm redirectOnSuccess={this.props.showHomePage} />
            <p>{ I18n.t('signup.alreadyHaveAnAccount') }<a onClick={ () => this.props.showHomePage() }>{ I18n.t('signup.email.connexionLinkLabel') }</a></p>
          </div>
        </section>
      </div>
    );
  }
}

EmailSignUp.propTypes = {
  showHomePage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  showHomePage: () => push('/')
}, dispatch)

export default connect(null, mapDispatchToProps)(EmailSignUp)
