import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

const TARIFICATION_MAX_LENGHT = 100;

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class WorkerFurtherInfo {

  constructor(furtherInfo) {
    this.weeklyAvailability = furtherInfo.weeklyAvailability || '';
    this.currentStatus = furtherInfo.currentStatus || '';
    this.currentStatusEndDate = furtherInfo.currentStatusEndDate || '';
    this.tarification = furtherInfo.tarification || '';
    this.tarification = furtherInfo.tarification || '';
    this.consultationRate = furtherInfo.consultationRate || '';
    this.currency = furtherInfo.currency || 0; //Add this back to '' when other currencies are supported
    this.portfolio = furtherInfo.portfolio || '';
    this.twitter = furtherInfo.twitter || '';
    this.linkedin = furtherInfo.linkedin || '';
    this.countries = furtherInfo.countries || [];
    this.onSite = !!furtherInfo.onSite;
    this.remote = !!furtherInfo.remote;
    this.acceptPredefinedBudget = !!furtherInfo.acceptPredefinedBudget;
  }

  equals(other) {
    return (
      !!other &&
      this.weeklyAvailability === other.weeklyAvailability &&
      this.currentStatus === other.currentStatus &&
      this.currentStatusEndDate === other.currentStatusEndDate &&
      this.tarification === other.tarification &&
      this.consultationRate === other.consultationRate &&
      this.currency === other.currency &&
      this.portfolio === other.portfolio &&
      this.twitter === other.twitter &&
      this.linkedin === other.linkedin &&
      this.countries === other.countries
    );
  }

  isValid() {
    return (
      notEmpty(this.currentStatus) &&
      notEmpty(this.weeklyAvailability) &&
      notEmpty(this.tarification) && this.tarification.length <= TARIFICATION_MAX_LENGHT
    );
  }

  getErrors() {
    const errors = {};

    if (!notEmpty(this.currentStatus)) {
      errors.currentStatus = I18n.t('worker.errors.furtherInfo.currentStatus.required');
    }

    if (!notEmpty(this.weeklyAvailability)) {
      errors.weeklyAvailability = I18n.t('worker.errors.furtherInfo.weeklyAvailability.required');
    }
    
    if (!notEmpty(this.tarification)) {
      errors.tarification = I18n.t('worker.errors.furtherInfo.tarification.required');
    } else if (this.tarification.length > TARIFICATION_MAX_LENGHT) {
      errors.tarification = I18n.t('worker.errors.furtherInfo.tarification.tooLong');
    }

    return errors;
  }
}

export default WorkerFurtherInfo;

export const WorkerFurtherInfoShape = PropTypes.shape({
  weeklyAvailability: PropTypes.string,
  currentStatus: PropTypes.string,
  currentStatusEndDate: PropTypes.string,
  onSite: PropTypes.boolean,
  remote: PropTypes.boolean,
  tarification: PropTypes.string,
  acceptPredefinedBudget: PropTypes.boolean,
  portfolio: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  countries: PropTypes.array
});