import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CompanyHeaderProfile from '../components/CompanyHeaderProfile';
import CompanyHeaderProjectStatus from '../components/CompanyHeaderProjectStatus';
import CompanyHeaderSwitchProfile from '../components/CompanyHeaderSwitchProfile';

import { CompanyShape } from '../../model/Company';

class CompanyHeaderBar extends Component {

  render() {
    return (
      <div className="grid">
        <CompanyHeaderProfile user={ this.props.user } companyProfile={ this.props.companyProfile } />
        <CompanyHeaderProjectStatus user={ this.props.user } companyProfile={ this.props.companyProfile } />
        <CompanyHeaderSwitchProfile user={ this.props.user } />
      </div>
    );
  }
}

CompanyHeaderBar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  }),
  companyProfile: CompanyShape
};

CompanyHeaderBar.defaultProps = {
  user: undefined,
  companyProfile: undefined
};

export default CompanyHeaderBar;
