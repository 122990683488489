import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

import logoFooter from '../../assets/img/logo-footer.png';

class Footer extends Component {

  render() {
    return (
      <footer className="app-footer">
        <div className="grid">
        
          <div className="grid-item grid-item--1-4">
            <img src={ logoFooter } alt="Talents M" />
            <ul>
              <li><a href="https://lestalentsm.com/a-propos-des-talents-m/">{I18n.t('footer.about')}</a></li>
              <li><a href="https://lestalentsm.com/pre-inscription-talents/">{I18n.t('footer.join')}</a></li>
              <li><a href="https://lestalentsm.com/pre-inscription-recherche-de-talents-pour-mon-projet/">{I18n.t('footer.workWithUs')}</a></li>
            </ul>
          </div>

          <div className="grid-item grid-item--1-2">
            <p>
              {I18n.t('footer.help.sectionLabelLine1')}
              <br />
              {I18n.t('footer.help.sectionLabelLine2')}
            </p>

            <ul>
              <li>
                {I18n.t('footer.help.canada.label')}
                <br/>
                <a href={I18n.t('footer.help.canada.phoneHref')}>{I18n.t('footer.help.canada.phone')}</a>
                {I18n.t('footer.help.canada.schedule')}
              </li>
              <li>
                {I18n.t('footer.help.france.label')}
                <br/>
                <a href={I18n.t('footer.help.france.phoneHref')}>{I18n.t('footer.help.france.phone')}</a>
                {I18n.t('footer.help.france.schedule')}
              </li>
              <li>
                <strong>
                  <a href={ 'mailto:' + I18n.t('footer.contactEmail')}>{I18n.t('footer.contactEmail')}</a>
                </strong>
              </li>
            </ul>
          </div>

          <div className="grid-item grid-item--1-4">
            <p>{I18n.t('footer.followUs')}</p>
            <ul className="social-networks">
              <li className="icon-social icon-facebook">
                <a href="https://www.facebook.com/lestalentsm/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li className="icon-social icon-linkedin">
                <a href="https://www.linkedin.com/company/11365908/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li className="icon-social icon-instagram">
                <a href="https://www.instagram.com/communautelestalentsm/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid-item copyright">
            <ul>
              <li>
                <a href="https://lestalentsm.com/mentions-legales/">
                  {I18n.t('footer.legalNotice')}
                </a>
              </li>
              <li>
                <a href="https://lestalentsm.com/conditions-dutilisation/">
                  {I18n.t('footer.termsOfUse')}
                </a>
              </li>
              <li>{I18n.t('footer.copyright')} {(new Date()).getFullYear()}</li>
            </ul>
          </div>

        </div>
      </footer>
    );
  }
}

export default Footer;