import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class ProjectSummary {

  constructor(projectSummary) {
    Object.assign(this, projectSummary);
  }

  equals(other) {
    return (
      !!other &&
      this.title === other.title &&
      this.description === other.description &&
      this.budget === other.budget
    );
  }

  isValid() {
    return (
      notEmpty(this.title) &&
      notEmpty(this.description) &&
      notEmpty(this.budget)
    );
  }

  getErrors() {
    const errors = {};

    if (!notEmpty(this.title)) {
      errors.title = I18n.t('project.errors.title.required');
    }

    if (!notEmpty(this.description)) {
      errors.description = I18n.t('project.errors.description.required');
    }

    if (!notEmpty(this.budget)) {
      errors.budget = I18n.t('project.errors.budget.required');
    }

    return errors;
  }
}

export default ProjectSummary;

export const ProjectSummaryShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  budget: PropTypes.string
});