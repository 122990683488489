const worker = {
  availability: {
    available: 'Disponible pour travailler',
    occupied: 'Non disponible',
    notSet: 'Non communiqué',
    until: 'jusqu\'au'
  },
  tarification: {
    notSet: 'Non communiqué'
  },
  profile: {
    details: {
      currentStatus: 'Statut:',
      workMode: 'Mode de travail:',
      remote: 'à distance',
      and: 'et',
      onSite: 'sur site',
      billingMode: 'Mode de facturation:',
      about: 'À propos de',
      callToAction:'Ce profil vous intéresse? Prenez contact avec l\'équipe de les Talents M en leur adressant un courriel à',
      adminContact:'contact@lestalentsm.com'
    },
    form: {
      title: 'Devenir talent',
      savedMessage: 'Les changements ont bien été enregistrés',
      initialSubTitle: ', complete tes informations !',
      updatedSubTitle: ', mets à jour tes informations !',
      subTitleText: 'Ces informations sont destinées à donner envie de collaborer avec toi, sur les projets qui t\'intéressent. C\'est le moment de mettre en avant ce qui te rend unique ;)',
      availability: {
        title: 'Disponibilité',
        weeklyAvailabilityTitle: 'Disponibilité en heures / semaine',
        locationTitle: 'Tu peux réaliser les projets...',
        field: {
          weeklyAvailability: 'Disponibilité en heure/semaine',
          weeklyAvailabilityPlaceholder: 'ex: 40h (temps plein / temps partiel)',
          currentStatus: 'Actuellement',
          currentStatusPlaceholder: 'Choisir',
          currentStatusEndDate: 'Jusqu\'à quand ?',
          currentStatusEndDatePlaceholder: 'ex: 2018/12/31',
          onSite: 'Sur site',
          remote: 'À distance'
        }
      },
      summary: {
        field: {
          fullName: 'Prénom et nom',
          fullNamePlaceholder: 'Votre nom complet',
          picture: 'Photo de profil',
          profileTitle: 'Titre de ton profil',
          profileTitlePlaceholder: 'ex: Designer web',
          city: 'Ville où tu résides actuellement',
          cityPlaceholder: 'ex: Montréal',
          description: 'Présentation (tu es unique, ne l\'oublie pas)',
          descriptionPlaceholder: 'Une rapide présentation de toi, qui es-tu, ce que tu souhaites mettre en avant de ton expérience ou de ta formation, ce qui te rend unique...',
          keyPhrase: 'Ta phrase d\'accroche (120 caractères max)',
          keyPhrasePlaceholder: 'ex: "Ma mission est de créer une connexion émotionnelle entre une entreprise et sa clientèle"'
        }
      },
      skills: {
        title: 'Quelles sont tes expertises ?',
        subTitle: 'Sélectionnez jusqu\'à deux de vos compétences principales',
        rateHelper: 'Selon toi, où se situe ton expertise sur une échelle de 1 à 5 ? (5 étant le plus haut grade)'
      },
      portfolio: {
        title: 'Portfolio ou références en ligne',
        field: {
          portfolio: 'Portfolio',
          portfolioPlaceholder: 'Portfolio',
          twitter: 'Twitter',
          twitterPlaceholder: 'Twitter',
          linkedin: 'LinkedIn',
          linkedinPlaceholder: 'LinkedIn'
        }
      },
      billing: {
        title: 'Facturation des projets',
        projectBillingTitle: 'Comment factures-tu tes projets ?',
        consultationBillingTitle:'Comment factures-tu tes consultations ?',
        predefinedBudgetTitle: 'Acceptes-tu les missions à budget prédéfini ?',
        field: {
          tarification: 'Mode de tarification (100 caractères max)',
          consultationRate: 'Tarif horaire pour une consultation (en dollar canadien)',
          tarificationPlaceholder: 'Ex: 50$/heure, 250$/jour, 2000$/projet, 1$/mot,...',
          consultationRatePlaceholder: 'Ex: 50, 60...',
          predefinedBudget: {
            yes: 'Oui',
            no: 'Non'
          }
        }
      },
      currencies:{
        field: {
          placeholder: 'Choisir une devise'
        }
      },
      countries: {
        title: 'Pays dans lesquels tu as travaillé',
        field: {
          placeholder: 'Choisir un pays',
          country1: 'Pays 1',
          country2: 'Pays 2',
          country3: 'Pays 3',
          country4: 'Pays 4'
        }
      },
      furtherInfoSectionTitle: 'Encore quelques questions',
      saveButtonLabel: 'Enregister mes informations'
    }
  },
  tile: {
    seeProfileButtonLabel: 'Voir le profil'
  },
  errors: {
    summary: {
      name: {
        required: 'Ce champ est obligatoire'
      },
      title: {
        required: 'Ce champ est obligatoire'
      },
      city: {
        required: 'Ce champ est obligatoire'
      },
      description: {
        required: 'Ce champ est obligatoire'
      },
      keyPhrase: {
        required: 'Ce champ est obligatoire',
        tooLong: 'Ce champ ne peut contenir plus de 120 caractères'
      }
    },
    furtherInfo: {
      currentStatus: {
        required: 'Ce champ est obligatoire'
      },
      weeklyAvailability: {
        required: 'Ce champ est obligatoire'
      },
      tarification: {
        required: 'Ce champ est obligatoire',
        tooLong: 'Ce champ ne peut contenir plus de 100 caractères'
      }
    },
    skills: {
      selectAtLeastOne: 'Choisissez au moins une compétence',
      missingScore: 'L\'évaluation de la compétence est obligatoire'
    }
  }
};

export default worker;
