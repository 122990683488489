import company from './company';
import dashboard from './dashboard';
import filters from './filters';
import footer from './footer';
import login from './login';
import nav from './nav';
import project from './project';
import signup from './signup';
import worker from './worker';
import modal from './modal';

const fr = {
  company,
  dashboard,
  filters,
  footer,
  login,
  nav,
  project,
  signup,
  worker,
  modal
};

export default fr;
