import PropTypes from 'prop-types';
import CompanyContactInformation, { CompanyContactInformationShape } from './CompanyContactInformation';
import CompanyDetails, { CompanyDetailsShape } from './CompanyDetails';
import { I18n } from 'react-redux-i18n';

function areSameFields(original, comparison) {
  let same = true;
  if (original.mainField) {
    same = same && (original.mainField === comparison.mainField);
  }
  if (original.secondaryField) {
    same = same && (original.secondaryField === comparison.secondaryField);
  }
  return same;
}

class Company {

  constructor(companyInfo) {
    this.ownerId = companyInfo.ownerId;
    this.details = new CompanyDetails(companyInfo.details);
    this.contactInformation = new CompanyContactInformation(companyInfo.contactInformation);
    this.activityFields = companyInfo.activityFields;
  }

  equals(other) {
    return (
      !!other &&
      !!this.details && !!this.contactInformation &&
      this.details.equals(other.details) &&
      this.contactInformation.equals(other.contactInformation) &&
      areSameFields(this.activityFields, other.activityFields)
    );
  }

  isValid() {
    return (
      this.details.areValid() &&
      this.contactInformation.areValid() &&
      !!this.activityFields && !!this.activityFields.mainField
    );
  }

  getErrors() {
    const errors = {
      details: this.details.getErrors(),
      contactInformation: this.contactInformation.getErrors()
    };

    if (!this.activityFields || !this.activityFields.mainField) {
      errors.activityFields = I18n.t('company.errors.activityFields.selectAtLeastOne');
    }

    return errors;
  }
}

export default Company;

export const CompanyShape = PropTypes.shape({
  details: CompanyDetailsShape,
  contactInformation: CompanyContactInformationShape,
  activityFields: PropTypes.shape({
    mainField: PropTypes.String,
    secondaryField: PropTypes.String
  })
});