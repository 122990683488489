import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CompanyDashboardSummary from '../containers/CompanyDashboardSummary';
import CompanyProfileForm from '../forms/CompanyProfileForm';
import ProjectBrowser from '../containers/ProjectBrowser';
import ProjectDetails from '../components/ProjectDetails';
import EditProjectForm from '../forms/EditProjectForm';
import WorkerProfileDetails from '../components/WorkerProfileDetails';
import ProjectForm from '../forms/ProjectForm';
import CompanyHeaderBar from '../containers/CompanyHeaderBar';
import CompanyNavigationBar from '../components/CompanyNavigationBar';
import WorkerProfileBrowser from '../containers/WorkerProfileBrowser';

import { loadCompanyProfile } from '../../reducers/company';

import { CompanyShape } from '../../model/Company';

class CompanyDashboard extends Component {

  componentDidMount() {
    this.props.loadCompanyProfile(this.props.user.uid);
  }

  render() {
    return (
      <div>
        <section className="section section--profile-header section--profile-header--company">
          <CompanyHeaderBar user={ this.props.user } companyProfile={ this.props.companyProfile } />
        </section>

        <nav className="section section--profile-nav">
          <CompanyNavigationBar />
        </nav>

        <section className="section section--profile-dashboard">          
          <Route exact path="/company" component={ CompanyDashboardSummary } />
          <Route exact path="/company/profile" component={ CompanyProfileForm } />
          <Route exact path="/company/profile/:profileId" component={ WorkerProfileDetails } />
          <Route exact path="/company/profiles" component={ WorkerProfileBrowser } />
          <Route exact path="/company/project" component={ ProjectForm } />
          <Route exact path="/company/project/:projectId" component={ ProjectDetails } />
          <Route exact path="/company/project/edit/:projectId" component={ EditProjectForm } />
          <Route exact path="/company/projects" component={ ProjectBrowser } />
        </section>
      </div>
    );
  }
}

CompanyDashboard.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  companyProfile: CompanyShape,
  companyProfileLoading: PropTypes.bool.isRequired,
  loadCompanyProfile: PropTypes.func.isRequired
};

CompanyDashboard.defaultProps = {
  user: undefined,
  companyProfile: undefined,
  companyProfileLoading: true
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  companyProfile: state.company.profile,
  companyProfileLoading: state.company.profileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanyProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard);
