import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { loadSkills } from '../../reducers/data';
import { loadWorkerProfile } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';

class SkillsFilter extends Component {

  constructor(props) {
    super(props);

    this.displaySkillGroup = this.displaySkillGroup.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    this.selectUserSkills = this.selectUserSkills.bind(this);
  }

  componentDidMount() {
    this.props.loadSkills();
    if (this.props.enableCustomFilters && this.props.user) {
      this.props.loadWorkerProfile(this.props.user.uid);
    }
  }

  handleChange(skillId) {
    let selectedSkills = this.props.selectedSkills;
    if (this.props.selectedSkills.includes(skillId)) {
      selectedSkills = selectedSkills.filter(id => id !== skillId);
    } else {
      selectedSkills = selectedSkills.concat(skillId);
    }
    this.props.updateFilters(selectedSkills);
  }

  selectAll() {
    this.props.updateFilters(this.props.groupedSkills
        .map(group => group.skills)
        .map(skills => skills.map(skill => skill.id))
        .reduce((flatArray, skills) => flatArray.concat(skills), []));
  }

  unselectAll() {
    this.props.updateFilters([]);
  }

  selectUserSkills() {
    if (this.props.workerProfile) {
      this.props.updateFilters(this.props.workerProfile.skills.map(evaluation => evaluation.skillId));
    }
  }

  displayCustomFilterButton() {
    if (this.props.enableCustomFilters) {
      return (
        <button
          className="btn small reverse"
          onClick={ this.selectUserSkills }>
          <span>{ I18n.t('filters.selectMineButtonLabel') }</span>
        </button>
      );
    }
    return;
  }

  displaySkillGroup(group) {
    return (
      <div key={ group.id } className="skills-list-item">
        <p className="skill-title">{ group.label }</p>
        <ul>
          { this.displaySkills(group.skills) }
        </ul>
      </div>
    );
  }

  displaySkills(skills) {
    return skills.map(skill => {
      const id = skill.id;
      return (
        <li
          className="skill-list-item"
          key={ id }>
          <input
            id={ `skill-${id}` }
            type="checkbox"
            value={ id }
            checked={ this.props.selectedSkills.includes(id) }
            onChange={ () => this.handleChange(id) }
          />
          <label htmlFor={ `skill-${id}` }>{ skill.label }</label>
        </li>
      );
    });
  }

  render() {
    if (this.props.groupedSkills.length > 0) {
      return (
        <div className="grid skills-list row">
          <div className="skills-list-buttons">
            <div className="buttons-container">
              { this.displayCustomFilterButton() }
              <button
                className="btn small reverse"
                onClick={ this.unselectAll }>
                <span>{ I18n.t('filters.unselectAllButtonLabel') }</span>
              </button>
            </div>
          </div>
          {
            this.props.groupedSkills.map(group => this.displaySkillGroup(group))
          }
        </div>
      );
    }
    return null;
  }
}

SkillsFilter.propTypes = {
  loadSkills: PropTypes.func.isRequired,
  groupedSkills: PropTypes.array,
  skillsLoading: PropTypes.bool,
  selectedSkills: PropTypes.arrayOf(PropTypes.number).isRequired,
  updateFilters: PropTypes.func.isRequired,
  enableCustomFilters: PropTypes.bool,
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  workerProfile: WorkerShape,
  workerProfileLoading: PropTypes.bool.isRequired,
  loadWorkerProfile: PropTypes.func.isRequired
};

SkillsFilter.defaultProps = {
  groupedSkills: [],
  skillsLoading: true,
  enableCustomFilters: false,
  user: undefined,
  workerProfile: undefined,
  workerProfileLoading: true
};

const mapStateToProps = state => ({
  groupedSkills: state.data.groupedSkills,
  skillsLoading: state.data.skillsLoading,
  user: state.authentication.user,
  workerProfile: state.worker.profile,
  workerProfileLoading: state.worker.profileLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSkills,
  loadWorkerProfile
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SkillsFilter);
