import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { loadSkills } from '../../reducers/data';
import { loadAllWorkers } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';

function filterGroupsBySkillsIds(skillIds, groups) {
  return groups.filter(group =>
    !!group.skills
      .map(skill => skill.id)
      .find(skillId => skillIds.includes(skillId))
  );
}

class WorkerProfileDetails extends Component {

  componentDidMount() {
    this.props.loadSkills();
    this.props.loadAllWorkers();
  }

  findProfile() {

    // TODO YARK to myself; change that for a get profile by id ASAP

    return this.props.profiles.find(profile => profile.id === this.props.match.params.profileId);
  }

  showSkills(profile) {
    if (!this.props.skillsLoading) {
      const skillsIds = profile.skills.map(evaluation => evaluation.skillId);
      const groups = filterGroupsBySkillsIds(skillsIds, this.props.groupedSkills);

      return groups.map(group => {
        return (
          <li className="skill-items" key={ group.id }>
            <ul>
              <p className="skill-tile-title">{ group.label }</p>
              {
                group.skills
                  .filter(skill => skillsIds.includes(skill.id))
                  .map(skill => <li key={ `${group.id}-${skill.id}` }><i className="fa fa-square" aria-hidden="true"></i>{ skill.label }</li>)
              }
            </ul>
          </li>
        );
      });
    }
    return null;
  }

  portfolio(data) {
    if (data) {
      return <div className="social"><i className="fa fa-envelope-o" aria-hidden="true"></i>{ data }</div>
    }
  }

  twitter(data) {
    if (data) {
      return <div className="social"><i className="fa fa-twitter" aria-hidden="true"></i>{ data }</div>
    }
  }

  facebook(data) {
    if (data) {
      return <div className="social"><i className="fa fa-facebook" aria-hidden="true"></i>{ data }</div>
    }
  }

  linkedin(data) {
    if (data) {
      return <div className="social"><i className="fa fa-linkedin" aria-hidden="true"></i>{ data }</div>
    }
  }

  showWorkMode(profile) {
    const onSite = profile.furtherInfo.onSite ? I18n.t('worker.profile.details.onSite') : undefined;
    const remote = profile.furtherInfo.remote ? I18n.t('worker.profile.details.remote') : undefined;
    if (onSite && remote) {
      return `${onSite} ${I18n.t('worker.profile.details.and')} ${remote}`;
    } else {
      return onSite || remote;
    }
  }

  showProfilePicture(profile) {
    if (profile && profile.summary && profile.summary.picture) {
      return <img className="avatar" src={ profile.summary.picture } alt="" />
    }
    return;
  }

  render() {
    if (this.props.profiles.length > 0) {
      const profile = this.findProfile();
      if (profile) {
        return (
          <div>
            <ScrollToTopOnMount />
            <div className="grid profile-details">
              <div className="grid-item">
                <div className="has-bg center">
                  { this.showProfilePicture(profile) }
                  <h3 className="name">{ profile.summary.name }</h3>
                  <p className="job">{ profile.summary.title }</p>
                  <p className="city">{ profile.summary.city }</p>
                </div>
                <div className="has-bg socials">
                  { this.portfolio(profile.furtherInfo.portfolio) }
                  { this.facebook(profile.furtherInfo.facebook) }
                  { this.twitter(profile.furtherInfo.twitter) }
                  { this.linkedin(profile.furtherInfo.linkedin) }
                </div>
              </div>
              <div className="grid-item has-bg half">
                <ul className="infos">
                  <li>{ I18n.t('worker.profile.details.currentStatus') } { profile.getAvailability() }</li>
                  <li>{ I18n.t('worker.profile.details.workMode') }  { this.showWorkMode(profile) }</li>
                </ul>
              </div>
              <div className="grid-item has-bg half">
                <ul className="infos">
                  <p>{ I18n.t('worker.profile.details.billingMode') } </p>
                  <p className="user-price">{ profile.furtherInfo.tarification }</p>
                </ul>
              </div>

              <div className="key-phrase">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <p>{ profile.summary.keyPhrase }</p>
                <i className="fa fa-quote-right" aria-hidden="true"></i>
              </div>
            </div>

            <div className="grid profile-about">
              <div className="grid-item half about">
                <p className="user-name">{ I18n.t('worker.profile.details.about') }  { profile.summary.name }</p>
                { profile.summary.description }
              </div>

              <div className="grid-item half aside-skills">
                <div>
                  <ul className="skills-list-container">
                    { this.showSkills(profile)}
                  </ul>
                </div>
              </div>

              <div className="grid-item">
                <div className="has-bg center">
                  <p className="call-to-action">{ I18n.t('worker.profile.details.callToAction') } <a href={"mailto:"+I18n.t('worker.profile.details.adminContact')}>{ I18n.t('worker.profile.details.adminContact') }</a></p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    return <p>Profile not found</p>;
  }
}

WorkerProfileDetails.propTypes = {
  profiles: PropTypes.arrayOf(WorkerShape).isRequired,
  profilesLoading: PropTypes.bool,
  skillsLoading: PropTypes.bool,
  loadSkills: PropTypes.func.isRequired,
  loadAllWorkers: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      profileId: PropTypes.string
    })
  }),
  groupedSkills: PropTypes.array
};

WorkerProfileDetails.defaultProps = {
  profiles: [],
  profilesLoading: false
};

const mapStateToProps = state => ({
  profiles: state.worker.profiles,
  profilesLoading: state.worker.profilesLoading,
  groupedSkills: state.data.groupedSkills,
  skillsLoading: state.data.skillsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadSkills,
  loadAllWorkers
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfileDetails);
