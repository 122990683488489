import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import ProjectSummaryForm from './ProjectSummaryForm';
import SkillsForm from './SkillsForm';
import ProjectDocumentsForm from './ProjectDocumentsForm';
import ModalMessage from '../components/ModalMessage';
import ConsultationsForm from './ConsultationsForm';

import Project, { ProjectShape } from '../../model/Project';

import { saveProject } from '../../reducers/project';
import { loadProjectStatuses } from '../../reducers/data';

import iconDownload from '../../assets/img/icon-download.svg';
import websiteTemplate from '../../assets/doc/creation_site_web_LTM.doc';
import designTemplate from '../../assets/doc/creation_design_LTM.doc';
import socialTemplate from '../../assets/doc/medias_sociaux_LTM.doc';
import contentTemplate from '../../assets/doc/redaction_de_contenus_LTM.doc';
import strategyTemplate from '../../assets/doc/strategie_marketing_LTM.doc';

const defaultState = {
  project: new Project({
    consultation: {},
    skills: [],
    summary: undefined,
    status: ''
  }),
  errors: {}
};

class ProjectForm extends Component {

  constructor(props) {
    super(props);

    this.updateSummary = this.updateSummary.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.updateSelectedSkills = this.updateSelectedSkills.bind(this);
    this.submitProject = this.submitProject.bind(this);
    this.handleFileUploaded = this.handleFileUploaded.bind(this);

    if (props.project) {
      this.state = {
        project: props.project,
        errors: {}
      };
    } else {
      this.state = defaultState;
    }
    this.state = Object.assign({}, this.state, { showSavedPopup: false });
  }

  componentWillMount() {
    if (this.props.projectStatuses.length === 0) {
      this.props.loadProjectStatuses();
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = Object.assign({}, this.state);

    if (nextProps.summary && Object.keys(nextProps.summary).length > 0) {
      Object.assign(newState, nextProps.summary);
    }
    if (!this.props.projectSaved && nextProps.projectSaved) {
      Object.assign(newState, { showSavedPopup: true });
    }
    this.setState(newState);
  }

  updateSummary(summary) {
    const project = new Project(Object.assign({}, this.state.project, { summary }));
    this.setState(Object.assign({}, this.state, { project }));
  }

  handleStatusChange(event) {
    const project = new Project(Object.assign({}, this.state.project, { status: event.target.value }));
    this.setState(Object.assign({}, this.state, { project }));
  }

  updateSelectedConsultation = (consultation) => {
    const project = new Project(Object.assign({}, this.state.project, { consultation }));
    this.setState(Object.assign({}, this.state, { project }));
  }

  updateSelectedSkills(skills) {
    const project = new Project(Object.assign({}, this.state.project, { skills }));
    this.setState(Object.assign({}, this.state, { project }));
  }

  handleFileUploaded(fileUrl) {
    const documents = this.state.project.documents.concat(fileUrl);
    const project = new Project(Object.assign({}, this.state.project, { documents }));
    this.setState(Object.assign({}, this.state, { project }));
  }

  submitProject() {
    const project = this.state.project;
    project.ownerId = this.props.user.uid;
    if (project.isValid()) {
      this.props.saveProject(project);
    } else {
      this.setState(Object.assign({}, this.state, { errors: project.getErrors() }));
    }
  }

  buildOptions() {
    if (!this.props.projectStatusesLoading) {
      return this.props.projectStatuses.map((status, index) => {
        return <option value={ status.id } key={ index }>{ status.label }</option>
      });
    }
  }

  getErrorClass(fieldName) {
    if (this.state.errors && this.state.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.state.errors && this.state.errors[fieldName]) {
      return <p>{ this.state.errors[fieldName] }</p>;
    }
  }

  showSavedPopup() {
    if (this.state.showSavedPopup) {
      return <ModalMessage
        redirect="/company"
        buttonLabel={ I18n.t('nav.company.backToDashboardButtonLabel') }
        message={ I18n.t('project.form.savedMessage') }
      />;
    }
  }

  render() {
    return (
      <div>
        { this.showSavedPopup() }

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ I18n.t('project.form.summary.title') }</span></h2>
              </div>
            </div>
          </header>

          <ProjectSummaryForm setSummary={ this.updateSummary } summary={ this.state.project.summary } errors={ this.state.errors.summary } />
        </section>

        <section className="section section--profile-form">
          <header>
            <div className="grid">

              <div className="grid-item">
                <h2><span>{ I18n.t('project.form.documents.title') }</span></h2>
                <p className="half">{ I18n.t('project.form.documents.subTitle') }</p>
              </div>

              <div className="grid-item">
                <h3 className="half-title">{ I18n.t('project.form.documents.stub.title') }</h3>
                <div className="modeles-list">
                  <a href={ websiteTemplate }  className="modele-item">
                    <p className="modele-name">{ I18n.t('project.form.document.websiteTemplate.label') }</p>
                    <img src={ iconDownload } alt=""/>
                  </a>
                  <a href={ designTemplate }  className="modele-item">
                    <p className="modele-name">{ I18n.t('project.form.document.designTemplate.label') }</p>
                    <img src={ iconDownload } alt=""/>
                  </a>
                  <a href={ socialTemplate }  className="modele-item">
                    <p className="modele-name">{ I18n.t('project.form.document.socialTemplate.label') }</p>
                    <img src={ iconDownload } alt=""/>
                  </a>
                  <a href={ contentTemplate }  className="modele-item">
                    <p className="modele-name">{ I18n.t('project.form.document.contentTemplate.label') }</p>
                    <img src={ iconDownload } alt=""/>
                  </a>
                  <a href={ strategyTemplate }  className="modele-item">
                    <p className="modele-name">{ I18n.t('project.form.document.strategyTemplate.label') }</p>
                    <img src={ iconDownload } alt=""/>
                  </a>
                </div>
                <p className="helper-message">{ I18n.t('project.form.documents.stub.text') }</p>
              </div>

              <div className="grid-item">
                <h3 className="half-title">{ I18n.t('project.form.documents.upload.title') }</h3>
                <ProjectDocumentsForm
                  fileUploadedCallback={ this.handleFileUploaded }
                  documents={ this.state.project.documents }
                />
              </div>
            </div>
          </header>
          <div className="grid">
            <div className="form has-bg">
              <h3>{ I18n.t('project.form.consultations.title') }</h3>
              <p>{ I18n.t('project.form.consultations.description') }</p>
              <ConsultationsForm setConsultation={ this.updateSelectedConsultation } selectedConsultation={ this.state.project.consultation }/>
              <p className="helper-message">{ I18n.t('project.form.consultations.details') }</p>
            </div>
          </div>
        </section>

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ I18n.t('project.form.skills.title') }</span></h2>
              </div>
            </div>
          </header>

          <SkillsForm setSkills={ this.updateSelectedSkills } skills={ this.state.project.skills } errors={ this.state.errors.skills }/>
        </section>

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ I18n.t('project.form.save.title') }</span></h2>
              </div>
            </div>
          </header>

            <div className="grid">
              <div className="grid-item form has-bg">
                <div className={ `field-wrapper ${this.getErrorClass('status')}` }>
                  <div className="field select-group">
                    <label htmlFor="status">{ I18n.t('project.form.summary.field.status') }</label>
                    <select
                      id="status"
                      onChange={ this.handleStatusChange }
                      value={ this.state.project.status }>
                      <option value="">{ I18n.t('project.form.summary.field.statusPlaceholder') }</option>
                      { this.buildOptions() }
                    </select>
                    { this.showError('status') }
                  </div>
                </div>

                <button onClick={ this.submitProject }>
                  <span>{ I18n.t('project.form.saveButtonLabel') }</span>
                </button>

            </div>
          </div>
        </section>
      </div>
    );
  }
}

ProjectForm.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  project: ProjectShape,
  summary: PropTypes.shape({}),
  saveProject: PropTypes.func.isRequired,
  projectStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projectStatusesLoading: PropTypes.bool.isRequired,
  loadProjectStatuses: PropTypes.func.isRequired,
  projectSaved: PropTypes.bool.isRequired
};

ProjectForm.defaultProps = {
  user: undefined,
  project: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  projectStatusesLoading: state.data.projectStatusesLoading,
  projectStatuses: state.data.projectStatuses,
  projectSaved: state.project.projectSaved
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveProject,
  loadProjectStatuses
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
