import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import countries from '../../config/countries';

class WorkerProfileCountriesForm extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      country1: '',
      country2: '',
      country3: '',
      country4: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries) {
      const newState = {
        country1: nextProps.countries[0] || '',
        country2: nextProps.countries[1] || '',
        country3: nextProps.countries[2] || '',
        country4: nextProps.countries[3] || ''
      }
      this.setState(newState);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    const countryList = [ newState.country1, newState.country2, newState.country3, newState.country4 ];
    this.props.setCountries(countryList);
    this.setState(newState);
  }

  buildOptions(index) {
    return countries.map(country => <option key={ index + '_' + country } value={ country }>{ country }</option>)
  }

  render() {
    return (
      <div className="form">
        <h3>{ I18n.t('worker.profile.form.countries.title') }</h3>
        <div className="fields-group selects-group wrap socials">

          <div className="select-group field half">
            <label htmlFor="country1">{ I18n.t('worker.profile.form.countries.field.country1') }</label>
            <select onChange={ this.handleChange } value={ this.state.country1 } id="country1" >
              <option value="">{ I18n.t('worker.profile.form.countries.field.placeholder') }</option>
              { this.buildOptions(1) }
            </select>
          </div>

          <div className="select-group field half">
            <label htmlFor="country2">{ I18n.t('worker.profile.form.countries.field.country2') }</label>
            <select onChange={ this.handleChange } value={ this.state.country2 } id="country2" >
              <option value="">{ I18n.t('worker.profile.form.countries.field.placeholder') }</option>
              { this.buildOptions(2) }
            </select>
          </div>

          <div className="select-group field half">
            <label htmlFor="country3">{ I18n.t('worker.profile.form.countries.field.country3') }</label>
            <select onChange={ this.handleChange } value={ this.state.country3 } id="country3" >
              <option value="">{ I18n.t('worker.profile.form.countries.field.placeholder') }</option>
              { this.buildOptions(3) }
            </select>
          </div>

          <div className="select-group field half">
            <label htmlFor="country4">{ I18n.t('worker.profile.form.countries.field.country4') }</label>
            <select onChange={ this.handleChange } value={ this.state.country4 } id="country4" >
              <option value="">{ I18n.t('worker.profile.form.countries.field.placeholder') }</option>
              { this.buildOptions(4) }
            </select>
          </div>
        </div>
      </div>
    );
  }
}

WorkerProfileCountriesForm.propTypes = {
  countries: PropTypes.array,
  setCountries: PropTypes.func.isRequired
};

WorkerProfileCountriesForm.defaultProps = {
  countries: undefined
};

export default WorkerProfileCountriesForm;
