import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { logout } from '../../reducers/authentication';

import logoHeader from '../../assets/img/logo-beta.png';

class Header extends Component {

  showLogoutButton() {
    if (this.props.user) {
      return <button className="logout" onClick={ this.props.logout }>{ I18n.t('nav.common.logout') }</button>;
    }
    return;
  }

  showMembershipButton() {
    if (this.props.user && this.props.user.isMember) {
      return <button className="membership" style={{ padding: 10 }} onClick={ () => this.onShowMembership() }>Gérer mon adhésion</button>;
    }
    return;
  }

  onShowMembership() {
    return window.open(`https://consultations.lestalentsm.com/membership/?customer=${this.props.user.id}`);
  }

  render() {
    return (
      <header className="app-header header">
        <a className="header--logo" href="/">
          <img src={ logoHeader } alt="talent-M logo"/>
        </a>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          { this.showMembershipButton() }
          { this.showLogoutButton() }
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    isMember: PropTypes.bool,
  }),
  logout: PropTypes.func.isRequired
};

Header.defaultProps = {
  user: undefined
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout
}, dispatch);

export default connect(undefined, mapDispatchToProps)(Header);
