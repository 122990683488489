const footer = {
  about: 'À propos des Talents M',
  join: 'Devenir Talent M',
  workWithUs: 'Travailler avec les Talents M',
  help: {
    sectionLabelLine1: 'Besoin d’un coup de main ?',
    sectionLabelLine2: 'Nous vous accompagnons de vive voix !',
    canada: {
      label: 'Au Canada',
      phone: '+ 1 514 726 8406',
      phoneHref: 'tel:+15147268406',
      schedule: ' (de 9h00 à 18h00)'
    },
    france: {
      label: 'En France',
      phone: '+ 33 (0)1 84 18 07 91',
      phoneHref: 'tel:+330184180791',
      schedule: ' (de 14h00 à 20h00)'
    }
  },
  followUs: 'Suivez-nous!',
  contactEmail: 'contact@lestalentsm.com',
  legalNotice: 'Mentions légales',
  termsOfUse: 'Conditions d’utilisation',
  copyright: '© Marcomnet'
};

export default footer;
