import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { CompanyDetailsShape } from '../../model/CompanyDetails';
import FileUploaderForm from './FileUploaderForm';

import { loadCompanySizes } from '../../reducers/data';

class CompanyDetailsForm extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleLogoUploaded = this.handleLogoUploaded.bind(this);

    this.state = {
      name: '',
      type: '',
      size: '',
      address: '',
      postalCode: '',
      city: '',
      province: '',
      country: '',
      logo: ''
    };
  }

  componentWillMount() {
    this.props.loadCompanySizes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.details) {
      this.setState(nextProps.details);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setDetails(newState);
    this.setState(newState);
  }

  handleLogoUploaded(logo) {
    const newState = Object.assign({}, this.state, { logo });
    this.props.setDetails(newState);
    this.setState(newState);
  }

  showCurrentLogo() {
    if (this.state.logo) {
      return <img src={ this.state.logo } alt="" style={{ 'max-width': '80px' }} />
    }
  }

  buildCompanySizes() {
    if (this.props.companySizes) {
      return this.props.companySizes.map(size => <option key={ size.id } value={ size.id }>{ size.label }</option>);
    }
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p>{ this.props.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div>
        <h3>{ I18n.t('company.profile.form.details.title') }</h3>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('name')}` }>
            <label htmlFor="name">{ I18n.t('company.profile.form.details.field.name') }</label>
            { this.showError('name') }
            <div className="field">
            <input
              id="name"
              type="text"
              value={ this.state.name }
              onChange={ this.handleChange }
              placeholder={ I18n.t('company.profile.form.details.field.namePlaceholder') }
            />
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('type')}` }>
            <label htmlFor="type">{ I18n.t('company.profile.form.details.field.type') }</label>
            { this.showError('type') }
            <div className="field select">
              <select
                id="type"
                onChange={ this.handleChange }
                value={ this.state.type }
              >
                <option value="">{ I18n.t('company.profile.form.details.field.typePlaceholder') }</option>
                <option value="profit">{ I18n.t('company.profile.form.details.field.typeProfit') }</option>
                <option value="nonProfit">{ I18n.t('company.profile.form.details.field.typeNonProfit') }</option>
                <option value="cooperative">{ I18n.t('company.profile.form.details.field.typeCooperative') }</option>
                <option value="independent">{ I18n.t('company.profile.form.details.field.typeIndependent') }</option>
                <option value="publicAdministration">{ I18n.t('company.profile.form.details.field.typePublicAdministration') }</option>
              </select>
            </div>
          </div>
        </div>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('size')}` }>
            <label htmlFor="size">{ I18n.t('company.profile.form.details.field.size') }</label>
            { this.showError('size') }
            <div className="field select">
              <select
                id="size"
                onChange={ this.handleChange }
                value={ this.state.size }
              >
                <option value="">{ I18n.t('company.profile.form.details.field.sizePlaceholder') }</option>
                { this.buildCompanySizes() }
              </select>
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('logo')}` }>
            <FileUploaderForm
              fileUploadedCallback={ this.handleLogoUploaded }
              uploadDirectory="logos"
              allowedFileType="image/*"
              label={ I18n.t('company.profile.form.details.field.logo') }
            />
            { this.showCurrentLogo() }
          </div>
        </div>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('address')}` }>
            <label htmlFor="address">{ I18n.t('company.profile.form.details.field.address') }</label>
            { this.showError('address') }
            <div className="field">
              <input
                id="address"
                type="text"
                value={ this.state.address }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.details.field.addressPlaceholder') }
              />
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('postalCode')}` }>
            <label htmlFor="postalCode">{ I18n.t('company.profile.form.details.field.postalCode') }</label>
            { this.showError('postalCode') }
            <div className="field">
              <input
                id="postalCode"
                type="text"
                value={ this.state.postalCode }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.details.field.postalCodePlaceholder') }
              />
            </div>
          </div>
        </div>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('city')}` }>
            <label htmlFor="city">{ I18n.t('company.profile.form.details.field.city') }</label>
            { this.showError('city') }
            <div className="field">
              <input
                id="city"
                type="text"
                value={ this.state.city }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.details.field.cityPlaceholder') }
              />
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('province')}` }>
            <label htmlFor="province">{ I18n.t('company.profile.form.details.field.province') }</label>
            { this.showError('province') }
            <div className="field">
              <input
                id="province"
                type="text"
                value={ this.state.province }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.details.field.provincePlaceholder') }
              />
            </div>
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('country')}` }>
          <label htmlFor="country">{ I18n.t('company.profile.form.details.field.country') }</label>
          { this.showError('country') }
          <div className="field">
            <input
              id="country"
              type="text"
              value={ this.state.country }
              onChange={ this.handleChange }
              placeholder={ I18n.t('company.profile.form.details.field.countryPlaceholder') }
            />
          </div>
        </div>
      </div>
    );
  }
}

CompanyDetailsForm.propTypes = {
  setDetails: PropTypes.func.isRequired,
  details: CompanyDetailsShape,
  companySizes: PropTypes.array,
  companySizesLoading: PropTypes.bool,
  loadCompanySizes: PropTypes.func.isRequired,
  errors: PropTypes.shape({})
};

CompanyDetailsForm.defaultProps = {
  details: undefined,
  companySizes: [],
  companySizesLoading: false
};

const mapStateToProps = state => ({
  companySizes: state.data.companySizes,
  companySizesLoading: state.data.companySizesLoading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanySizes
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsForm);
