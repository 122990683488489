const signup = {
  title: 'Rejoignez la communauté !',
  subTitle: 'inscrivez-vous avec',
  or: 'ou',
  withEmail: 'M\'inscrire avec une adresse courriel',
  alreadyHaveAnAccount: 'Vous avez deja un compte Les Talents M ?',
  connexionLinkLabel: 'Connexion',
  email: {
    title: 'Vos informations d\'inscription',
    connexionLinkLabel: 'Connexion',
    signupButtonLabel: 'M\'inscrire',
    field: {
      firstName: 'Prénom',
      firstNamePlaceholder: 'Votre prénom',
      lastName: 'Nom',
      lastNamePlaceholder: 'Votre nom',
      email: 'Adresse courriel',
      emailPlaceholder: 'Votre adresse courriel',
      password: 'Mot de passe',
      passwordPlaceholder: 'Choisissez un mot de passe',
      passwordConfirmation: 'Confirmation du mot de passe',
      passwordConfirmationPlaceholder: 'Confirmez votre mot de passe'
    }
  },
  error: {
    email: {
      invalid: 'L\'adresse courriel saisie est invalide',
      required: 'Ce champ est obligatoire',
      used: 'Cette adresse courriel n\'est pas disponible'
    },
    firstName: {
      required: 'Ce champ est obligatoire'
    },
    lastName: {
      required: 'Ce champ est obligatoire'
    },
    password: {
      required: 'Ce champ est obligatoire',
      weak: 'Le mot de passe doit contenir au minimum 6 caractéres'
    },
    confirmation: {
      match: 'Ce champ ne correspond pas au contenu du champ mot de passe'
    },
    unknown: 'Une erreur est survenue lors de la tentative de connexion'
  }
};

export default signup;