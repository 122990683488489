import React, { Component } from 'react'
import PropTypes from 'prop-types';

import WorkerProfileAvailabilityForm from './WorkerProfileAvailabilityForm';
import WorkerProfileBillingForm from './WorkerProfileBillingForm';
import WorkerProfilePortfolioForm from './WorkerProfilePortfolioForm';
import WorkerProfileCountriesForm from './WorkerProfileCountriesForm';

class WorkerProfileFurtherInfoForm extends Component {

  constructor() {
    super();

    this.updateAvailability = this.updateAvailability.bind(this);
    this.updateBilling = this.updateBilling.bind(this);
    this.updatePortfolio = this.updatePortfolio.bind(this);
    this.updateCountries = this.updateCountries.bind(this);

    this.state = {
      availability: undefined,
      portfolio: undefined,
      billing: undefined,
      countries: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.furtherInfo && Object.keys(nextProps.furtherInfo).length > 0) {
      const { weeklyAvailability, currentStatus, currentStatusEndDate, onSite, remote } = nextProps.furtherInfo;
      const { portfolio, twitter, linkedin } = nextProps.furtherInfo;
      const { tarification, acceptPredefinedBudget, consultationRate, currency } = nextProps.furtherInfo;
      const { countries } = nextProps.furtherInfo;
      this.setState({
        availability: { weeklyAvailability, currentStatus, currentStatusEndDate, onSite, remote },
        portfolio: { portfolio, twitter, linkedin },
        billing: { tarification, acceptPredefinedBudget, consultationRate, currency },
        countries
      });
    }
  }

  updateAvailability(availability) {
    this.setState(Object.assign({}, this.state, { availability }));
    this.props.setFurtherInfo(Object.assign({}, this.state.portfolio, this.state.billing, this.state.countries, availability));
  }

  updateBilling(billing) {
    this.setState(Object.assign({}, this.state, { billing }));
    this.props.setFurtherInfo(Object.assign({}, this.state.availability, this.state.portfolio, this.state.countries, billing));
  }

  updatePortfolio(portfolio) {
    this.setState(Object.assign({}, this.state, { portfolio }));
    this.props.setFurtherInfo(Object.assign({}, this.state.availability, this.state.billing, this.state.countries, portfolio));
  }

  updateCountries(countries) {
    this.setState(Object.assign({}, this.state, { countries }));
    this.props.setFurtherInfo(Object.assign({}, this.state.availability, this.state.billing, this.state.portfolio, { countries }));
  }

  getAvailabilityErrors() {
    if (this.props.errors) {
      return {
        currentStatus: this.props.errors.currentStatus,
        weeklyAvailability: this.props.errors.weeklyAvailability
      };
    }
  }

  getBillingErrors() {
    if (this.props.errors) {
      return {
        tarification: this.props.errors.tarification
      };
    }
  }

  render() {
    return (
      <div className="form has-bg">
        <WorkerProfileAvailabilityForm
          setAvailability={ this.updateAvailability }
          availability={ this.state.availability }
          errors={ this.getAvailabilityErrors() }
        />
        <WorkerProfileBillingForm
          setBilling={ this.updateBilling }
          billing={ this.state.billing }
          errors={ this.getBillingErrors() }
        />
        <WorkerProfilePortfolioForm
          setPortfolio={ this.updatePortfolio }
          portfolio={ this.state.portfolio }
        />
        <WorkerProfileCountriesForm
          setCountries={ this.updateCountries }
          countries={ this.state.countries }
        />
      </div>
    );
  }
}

WorkerProfileFurtherInfoForm.propTypes = {
  setFurtherInfo: PropTypes.func.isRequired,
  furtherInfo: PropTypes.shape({}),
  errors: PropTypes.shape({
    currentStatus: PropTypes.string,
    weeklyAvailability: PropTypes.string,
    tarification: PropTypes.string
  })
};

WorkerProfileFurtherInfoForm.defaultProps = {
  furtherInfo: undefined
};

export default WorkerProfileFurtherInfoForm;
