import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import firebase from '../../config/firebase';

import { setUser } from '../../reducers/authentication';
import { initializeProfile } from '../../reducers/worker';

const INVALID_EMAIL_ERROR_CODE = 'auth/invalid-email';
const EMAIL_IN_USE_ERROR_CODE = 'auth/email-already-in-use';
const WEAK_PASSWORD_ERROR_CODE = 'auth/weak-passwor';

class FirebaseEmailSignupForm extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    this.handleChange = this.handleChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleChange(event, key) {
    this.setState({[key]: event.target.value});
  }

  isFormValid() {
    const errors = {};
    let valid = true;

    if (this.state.email === '') {
      valid = false;
      errors.email = I18n.t('signup.error.email.required');
    }

    if (this.state.firstName === '') {
      valid = false;
      errors.firstName = I18n.t('signup.error.firstName.required');
    }

    if (this.state.lastName === '') {
      valid = false;
      errors.lastName = I18n.t('signup.error.lastName.required');
    }

    if (this.state.password === '') {
      valid = false;
      errors.password = I18n.t('signup.error.password.required');
    }

    if (this.state.password !== this.state.confirmation) {
      valid = false;
      errors.confirmation = I18n.t('signup.error.confirmation.match');
    }

    this.setState(Object.assign({}, this.state, { errors }));
    return valid;
  }

  handleSignUp() {
    if (this.isFormValid()) {
      const { firstName, lastName, email, password } = this.state;
      const signupResult = firebase.auth().createUserWithEmailAndPassword(email, password);
      signupResult.then(() => {
        const firebaseUser = firebase.auth().currentUser;
        this.props.initializeProfile(firebaseUser.uid, { name:`${firstName} ${lastName}` });
        this.props.setUser();
        this.props.redirectOnSuccess();
      }).catch(err => {
        this.handleError(err);
      });
    }
  }

  handleError(error) {
    const errors = this.state.errors || {};
    if (error.code === INVALID_EMAIL_ERROR_CODE) {
      errors.email = I18n.t('signup.error.email.invalid');
    } else if (error.code === EMAIL_IN_USE_ERROR_CODE) {
      errors.email = I18n.t('signup.error.email.used');
    } else if (error.code === WEAK_PASSWORD_ERROR_CODE) {
      errors.password = I18n.t('signup.error.password.weak');
    } else {
      errors.unknown = I18n.t('signup.error.unknown');
    }
    this.setState(Object.assign({}, this.state, { errors }));
  }

  getErrorClass(fieldName) {
    if (this.state.errors && this.state.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.state.errors && this.state.errors[fieldName]) {
      return <p>{ this.state.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div className="form form--register">
        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('firstName')}` }>
            <div className="field">
              <label>{ I18n.t('signup.email.field.firstName') }</label>
              <input
              type="text"
              value={this.state.firstName}
              onChange={evt => this.handleChange(evt, 'firstName')}
              placeholder={ I18n.t('signup.email.field.firstNamePlaceholder') } />
              { this.showError('firstName') }
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('lastName')}` }>
            <div className="field">
              <label>{ I18n.t('signup.email.field.lastName') }</label>
              <input
              type="text"
              value={this.state.lastName}
              onChange={evt => this.handleChange(evt, 'lastName')}
              placeholder={ I18n.t('signup.email.field.lastNamePlaceholder') } />
              { this.showError('lastName') }
            </div>
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('email')}` }>
          <div className="field">
            <label>{ I18n.t('signup.email.field.email') }</label>
            <input
            type="text"
            value={this.state.email}
            onChange={evt => this.handleChange(evt, 'email')}
            placeholder={ I18n.t('signup.email.field.emailPlaceholder') } />
            { this.showError('email') }
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('password')}` }>
          <div className="field">
            <label>{ I18n.t('signup.email.field.password') }</label>
            <input
            type="password"
            value={this.state.password}
            onChange={evt => this.handleChange(evt, 'password')}
            placeholder={ I18n.t('signup.email.field.passwordPlaceholder') } />
            { this.showError('password') }
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('confirmation')}` }>
          <div className="field">
            <label>{ I18n.t('signup.email.field.passwordConfirmation') }</label>
            <input
            type="password"
            value={this.state.confirmation}
            onChange={evt => this.handleChange(evt, 'confirmation')}
            placeholder={ I18n.t('signup.email.field.passwordConfirmationPlaceholder') } />
            { this.showError('confirmation') }
          </div>
        </div>

        <div className={ `field-wrapper ${this.getErrorClass('unknown')}` }>
          <div className="field">
            { this.showError('unknown') }
            <button id="email-signup-button" name="email-signup-button" onClick={this.handleSignUp} className="btn reverse">
              <span>{ I18n.t('signup.email.signupButtonLabel') }</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FirebaseEmailSignupForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  confirmation: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
  initializeProfile: PropTypes.func.isRequired,
  redirectOnSuccess: PropTypes.func.isRequired
};

FirebaseEmailSignupForm.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmation: ''
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setUser,
  initializeProfile
}, dispatch)

export default connect(null, mapDispatchToProps)(FirebaseEmailSignupForm)
