import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import Company, { CompanyShape } from '../../model/Company';

import CompanyDetailsForm from './CompanyDetailsForm';
import CompanyActivityFieldForm from './CompanyActivityFieldForm';
import CompanyContactInformationForm from './CompanyContactInformationForm';
import ModalMessage from '../components/ModalMessage';

import { saveCompanyProfile, loadCompanyProfile } from '../../reducers/company';

function buildInitialData(state, currentProps, nextProps) {
  const newProfile = nextProps.profile;

  if (!currentProps.profileSaved && nextProps.profileSaved) {
    return Object.assign({}, state, {
      company: newProfile,
      showSavedPopup: true
    });
  }

  return Object.assign({}, state, {
    company: newProfile,
    showSavedPopup: false
  });
}

const defaultState = {
  company: new Company({
    details: undefined,
    activityFields: undefined,
    contactInformation: undefined
  }),
  errors: {}
};

class CompanyProfileForm extends Component {

  constructor(props) {
    super(props);

    this.updateActivityFields = this.updateActivityFields.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.updateContactInformation = this.updateContactInformation.bind(this);
    this.submitProfile = this.submitProfile.bind(this);

    if (props.profile) {
      this.state = {
        company: props.profile,
        errors: {}
      };
    } else {
      this.state = defaultState;
    }
    this.state = Object.assign({}, this.state, { showSavedPopup: false });
  }

  componentWillMount() {
    this.props.loadCompanyProfile(this.props.user.uid);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(buildInitialData(this.state, this.props, nextProps));
  }

  getSubtitleText = () => {
    const {profile} = this.props;
    if(profile){
      if (profile.isValid()){
        return I18n.t('company.profile.form.updatedSubTitle');
      }
    }
    return I18n.t('company.profile.form.initialSubTitle');
  }

  updateActivityFields(activityFields) {
    const company = new Company(Object.assign({}, this.state.company, { activityFields }));
    this.setState(Object.assign({}, this.state, { company }));
  }

  updateDetails(details) {
    const company = new Company(Object.assign({}, this.state.company, { details }));
    this.setState(Object.assign({}, this.state, { company }));
  }

  updateContactInformation(contactInformation) {
    const company = new Company(Object.assign({}, this.state.company, { contactInformation }));
    this.setState(Object.assign({}, this.state, { company }));
  }

  submitProfile() {
    const profile = this.state.company;
    if (profile.isValid()) {
      this.props.saveCompanyProfile(profile);
    } else {
      this.setState(Object.assign({}, this.state, { errors: profile.getErrors() }));
    }
  }

  showSavedPopup() {
    if (this.state.showSavedPopup) {
      return <ModalMessage
        redirect="/company"
        buttonLabel={ I18n.t('nav.company.backToDashboardButtonLabel') }
        message={ I18n.t('project.form.savedMessage') }
      />;
    }
  }

  render() {
    return (
      <div>
        { this.showSavedPopup() }

        <section className="section section--profile-form">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{ this.getSubtitleText() }</span></h2>
              </div>
            </div>
          </header>

          <div className="grid">
            <div className="form has-bg">
              <p>{ I18n.t('company.profile.form.help') }</p>
              <CompanyDetailsForm
                setDetails={ this.updateDetails }
                details={ this.state.company.details }
                errors={ this.state.errors.details }
              />
              <CompanyActivityFieldForm
                setActivityFields={ this.updateActivityFields }
                activityFields={ this.state.company.activityFields }
                errors={ this.state.errors.activityFields }
              />
              <CompanyContactInformationForm
                setContactInformation={ this.updateContactInformation }
                contactInformation={ this.state.company.contactInformation }
                errors={ this.state.errors.contactInformation }
              />
            </div>
          </div>
        </section>

        <section className="section section--profile-form">
          <div className="grid">
            <button onClick={ this.submitProfile } className="btn">
              <span>{ I18n.t('company.profile.form.saveButtonLabel') }</span>
            </button>
          </div>
        </section>
      </div>
    );
  }
}

CompanyProfileForm.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  profile: CompanyShape,
  profileLoading: PropTypes.bool.isRequired,
  loadCompanyProfile: PropTypes.func.isRequired,
  saveCompanyProfile: PropTypes.func.isRequired,
  profileSaved: PropTypes.bool.isRequired
};

CompanyProfileForm.defaultProps = {
  user: undefined,
  profile: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  profile: state.company.profile,
  profileLoading: state.company.profileLoading,
  profileSaved: state.company.profileSaved
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCompanyProfile,
  saveCompanyProfile
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileForm);
