import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { ProjectShape } from '../../model/Project';
import { CompanyShape } from '../../model/Company';

import projectDefault from '../../assets/img/project-default.png';

class ProjectTile extends Component {

  getLinkToProjectPage() {
    return `/${this.props.location.pathname.split('/')[1]}/project/${this.props.project.id}`;
  }

  getEditButton() {
    if (this.props.user && this.props.user.uid === this.props.project.ownerId) {
      return <Link to={ `/company/project/edit/${this.props.project.id}` } className="btn small reverse separate"><span>{ I18n.t('project.tile.editProjectButtonLabel') }</span></Link>
    }
  }

  render() {
    const project = this.props.project;
    return (
      <div className="grid-item grid-item--1-3">
        <div className="tile tile--project">

          <img className="image" src={ projectDefault } alt="default project" />

          <div className="content">
            <h2 className="title">{ project.summary.title }</h2>
            <p>
              { I18n.t('project.tile.startedOn') } { project.getReadableCreatedDate() }
              <br />
              { I18n.t('project.tile.startedBy') } <strong>{ this.props.company.details.name }</strong>
            </p>
            <Link to={ this.getLinkToProjectPage() } className="btn small reverse"><span>{ I18n.t('project.tile.seeProjectButtonLabel') }</span></Link>
            { this.getEditButton() }
          </div>
        </div>
      </div>
    );
  }
}

ProjectTile.propTypes = {
  project: ProjectShape.isRequired,
  company: CompanyShape.isRequired,
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

ProjectTile.defaultProps = {
  project: undefined,
  company: undefined,
  user: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  location: state.routing.location
})

export default connect(mapStateToProps)(ProjectTile);
