import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Header from './components/components/Header';
import Footer from './components/components/Footer';

import CompanyDashboard from './components/pages/CompanyDashboard';
import Dashboard from './components/pages/Dashboard';
import EmailSignUp from './components/pages/EmailSignUp';
import SignIn from './components/pages/SignIn';
import SocialSignUp from './components/pages/SocialSignUp';
import WorkerDashboard from './components/pages/WorkerDashboard';
import ForgotPassword from './components/pages/ForgotPassword';

import firebase from './config/firebase';
import { setUser } from './reducers/authentication';

class App extends Component {

  componentDidMount() {
    firebase.auth().onAuthStateChanged(() => {
      this.props.setUser();
    });
  }

  render() {
    if (this.props.user) {
      return (
        <main>
          <Header user={ this.props.user } />
          <Route exact path="/" component={Dashboard} />
          <Route path="/company" component={CompanyDashboard} />
          <Route path="/talent" component={WorkerDashboard} />
          <Footer/>
        </main>
      );
    } else {
      return (
        <main>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/signup" component={SocialSignUp} />
          <Route exact path="/email-signup" component={EmailSignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
        </main>
      );
    }
  }
}

App.propTypes = {
  user: PropTypes.shape({}),
  setUser: PropTypes.func.isRequired
};

App.defaultProps = {
  user: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setUser
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
