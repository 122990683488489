import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import CompanyActivityFieldSelectorForm from './CompanyActivityFieldSelectorForm';

class CompanyActivityFieldForm extends Component {

  constructor(props) {
    super(props);

    this.updateMainField = this.updateMainField.bind(this);
    this.updateSecondaryField = this.updateSecondaryField.bind(this);

    this.state = {
      mainField: undefined,
      secondaryField: undefined
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.activityFields);
  }

  updateMainField(mainField) {
    const newState = Object.assign({}, this.state, { mainField });
    this.updateActivityFields(newState);
    this.setState(newState);
  }

  updateSecondaryField(secondaryField) {
    const newState = Object.assign({}, this.state, { secondaryField });
    this.updateActivityFields(newState);
    this.setState(newState);
  }

  updateActivityFields(fields) {
    const newFields = {};
    if (fields.mainField) {
      newFields.mainField = fields.mainField;
    }
    if (fields.secondaryField) {
      newFields.secondaryField = fields.secondaryField;
    }
    this.props.setActivityFields(newFields);
  }

  showError() {
    if (this.props.errors && this.props.errors) {
      return <p className="error">{ this.props.errors }</p>;
    }
  }

  render() {
    return (
      <div>
        <h3>{ I18n.t('company.profile.form.activityFields.title') }</h3>
        { this.showError() }
        <CompanyActivityFieldSelectorForm
          setValue={ this.updateMainField }
          value={ this.state.mainField }
        />
        <CompanyActivityFieldSelectorForm
          setValue={ this.updateSecondaryField }
          value={ this.state.secondaryField }
        />
      </div>
    );
  }
}

CompanyActivityFieldForm.propTypes = {
  setActivityFields: PropTypes.func.isRequired,
  activityFields: PropTypes.shape({}),
  errors: PropTypes.string
};

CompanyActivityFieldForm.defaultProps = {
  activityFields: undefined
};

export default CompanyActivityFieldForm;
