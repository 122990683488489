import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import iconProject from '../../assets/img/icon-project.svg';
import iconTalent from '../../assets/img/icon-talent.svg';

class Dashboard extends Component {

  render() {

    return (
      <div>

        <section className="section section--dashboard">
          <header>
            <div className="grid">
              <div className="grid-item">
                <h2><span>{I18n.t('dashboard.subTitle')}</span></h2>
              </div>
            </div>
          </header>

          <div className="grid">
            <div className="grid-item grid-item--1-2">
              <section className="section section--tile">
                <img src={iconProject} alt=""/>
                <h3>{I18n.t('dashboard.company.sectionTitle')}</h3>
                <Link to="/company" className="btn red">
                  <span>{I18n.t('dashboard.company.buttonLabel')}</span>
                </Link>
              </section>
            </div>

            <div className="grid-item grid-item--1-2">
              <section className="section section--tile">
                <img src={iconTalent} alt=""/>
                <h3>{I18n.t('dashboard.worker.sectionTitle')}</h3>
                <Link to="/talent" className="btn blue">
                  <span>{I18n.t('dashboard.worker.buttonLabel')}</span>
                </Link>
              </section>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  })
};

Dashboard.defaultProps = {
  user: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
})

export default connect(mapStateToProps)(Dashboard);
