import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import ProjectBrowser from '../containers/ProjectBrowser';
import ProjectDetails from '../components/ProjectDetails';
import WorkerProfileDetails from '../components/WorkerProfileDetails';
import WorkerProfileForm from '../forms/WorkerProfileForm';
import WorkerHeaderBar from '../containers/WorkerHeaderBar';
import WorkerNavigationBar from '../components/WorkerNavigationBar';
import WorkerDashboardSummary from '../containers/WorkerDashboardSummary';
import WorkerProfileBrowser from '../containers/WorkerProfileBrowser';

import { loadWorkerProfile } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';

class WorkerDashboard extends Component {

  componentDidMount() {
    this.props.loadWorkerProfile(this.props.user.uid);
  }

  render() {
    return (
      <div>
        <section className="section section--profile-header section--profile-header--worker">
          <WorkerHeaderBar user={ this.props.user } workerProfile={ this.props.workerProfile } />
        </section>

        <nav className="section section--profile-nav">
          <WorkerNavigationBar />
        </nav>

        <section className="section section--profile-dashboard">          
          <Route exact path="/talent" component={ WorkerDashboardSummary } />
          <Route exact path="/talent/profile" component={ WorkerProfileForm } />
          <Route exact path="/talent/profile/:profileId" component={ WorkerProfileDetails } />
          <Route exact path="/talent/profiles" component={ WorkerProfileBrowser } />
          <Route exact path="/talent/project/:projectId" component={ ProjectDetails } />
          <Route exact path="/talent/projects" component={ ProjectBrowser } />
        </section>
      </div>
    );
  }
}

WorkerDashboard.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  workerProfile: WorkerShape,
  workerProfileLoading: PropTypes.bool.isRequired,
  loadWorkerProfile: PropTypes.func.isRequired
};

WorkerDashboard.defaultProps = {
  user: undefined,
  workerProfile: undefined,
  workerProfileLoading: true
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  workerProfile: state.worker.profile,
  workerProfileLoading: state.worker.profileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadWorkerProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkerDashboard);
