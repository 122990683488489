import PropTypes from 'prop-types';
import WorkerSummary, { WorkerSummaryShape } from './WorkerSummary';
import WorkerFurtherInfo, { WorkerFurtherInfoShape } from './WorkerFurtherInfo';
import { I18n } from 'react-redux-i18n';

class Worker {

  constructor(workerInfo) {
    this.id = workerInfo.id;
    this.skills = workerInfo.skills || [];
    this.summary = new WorkerSummary(workerInfo.summary || {});
    this.furtherInfo = new WorkerFurtherInfo(workerInfo.furtherInfo || {});
  }

  getAvailability() {
    const status = this.furtherInfo.currentStatus;
    const endDate = this.furtherInfo.currentStatusEndDate;
    if (status && '' !== status) {
      const until = (endDate !== '') ? ` ${I18n.t('worker.availability.until')} ${endDate}` : '';
      return I18n.t(`worker.availability.${status}`) + until;
    }
    return I18n.t('worker.availability.notSet');
  }

  getTarification() {
    const tarification = this.furtherInfo.tarification;
    if (tarification && '' !== tarification) {
      return tarification;
    }
    return I18n.t('worker.tarification.notSet');
  }

  equals(other) {
    return (
      !!other &&
      this.summary.equals(other.summary) &&
      this.furtherInfo.equals(other.furtherInfo)
    );
  }

  isValid() {
    return (
      this.id &&
      this.summary.isValid() &&
      this.furtherInfo.isValid() &&
      !!this.areSkillsValid()
    );
  }

  areSkillsValid() {
    return (
      Array.isArray(this.skills) &&
      this.skills.length > 0 &&
      this.skills.find(evaluation => evaluation.score)
    );
  }

  getErrors() {
    const errors = {
      summary: this.summary.getErrors(),
      furtherInfo: this.furtherInfo.getErrors()
    };

    if (this.skills.length < 1) {
      errors.skills = { empty: I18n.t('worker.errors.skills.selectAtLeastOne') };
    } else {
      let hasSkillWithNoScore = false;
      const scoreErrors = {};
      this.skills.forEach(evaluation => {
        if (!evaluation.score) {
          scoreErrors[evaluation.skillId] = I18n.t('worker.errors.skills.missingScore');
          hasSkillWithNoScore = true;
        }
      });
      if (hasSkillWithNoScore) {
        errors.skills = scoreErrors;
      }
    }

    return errors;
  }
}

export default Worker;

export const WorkerShape = PropTypes.shape({
  summary: WorkerSummaryShape,
  furtherInfo: WorkerFurtherInfoShape,
  skills: PropTypes.array
});