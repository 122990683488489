import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import SignInForm from '../forms/SignInForm';

import logoWhite from '../../assets/img/logo-white.svg';

class SignIn extends Component {

  render() {
    return (
      <div className="page page--login">
        <section className="section section--splash">
          <img src={logoWhite} alt="Les Talents M." />
        </section>
        <section className="section section--login">
          <SignInForm redirectOnSuccess={this.props.showHomePage}/>
        </section>
      </div>
    );
  }
}

SignIn.propTypes = {
  showHomePage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  showHomePage: () => push('/')
}, dispatch)

export default connect(null, mapDispatchToProps)(SignIn)
