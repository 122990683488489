import { Component } from 'react';

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 70)
  }
  
  render() {
    return null
  }
}

export default ScrollToTopOnMount;
