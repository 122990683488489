import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

function notEmpty(value) {
  return !!value && value.trim().length > 0;
}

class CompanyContactInformation {

  constructor(contactInformation) {
    Object.assign(this, contactInformation);
  }

  equals(other) {
    return (
      this.email === other.email &&
      this.phone === other.phone &&
      this.skype === other.skype &&
      this.website === other.website
    );
  }

  areValid() {
    return (
      notEmpty(this.email) &&
      notEmpty(this.phone)
    );
  }

  getErrors() {
    const errors = {};

    if (!notEmpty(this.email)) {
      errors.email = I18n.t('company.errors.email.required');
    }

    if (!notEmpty(this.phone)) {
      errors.phone = I18n.t('company.errors.phone.required');
    }

    return errors;
  }
}

export default CompanyContactInformation;

export const CompanyContactInformationShape = PropTypes.shape({
  enail: PropTypes.String,
  phone: PropTypes.String,
  skype: PropTypes.String,
  website: PropTypes.String
});