import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import { CompanyContactInformationShape } from '../../model/CompanyContactInformation';

class CompanyContactInformation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      email: '',
      phone: '',
      skype: '',
      website: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactInformation) {
      this.setState(nextProps.contactInformation);
    }
  }

  handleChange(event) {
    const newState = Object.assign({}, this.state, { [event.target.id]: event.target.value });
    this.props.setContactInformation(newState);
    this.setState(newState);
  }

  getErrorClass(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return 'error';
    }
  }

  showError(fieldName) {
    if (this.props.errors && this.props.errors[fieldName]) {
      return <p>{ this.props.errors[fieldName] }</p>;
    }
  }

  render() {
    return (
      <div>
        <h3>{ I18n.t('company.profile.form.contact.title') }</h3>

        <div className="fields-group">
          <div className={ `field-wrapper ${this.getErrorClass('email')}` }>
            <label htmlFor="email">{ I18n.t('company.profile.form.contact.field.email') }</label>
            { this.showError('email') }
            <div className="field">
              <input
                id="email"
                type="text"
                value={ this.state.email }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.contact.field.emailPlaceholder') }
              />
            </div>
          </div>

          <div className={ `field-wrapper ${this.getErrorClass('phone')}` }>
            <label htmlFor="phone">{ I18n.t('company.profile.form.contact.field.phone') }</label>
            { this.showError('phone') }
            <div className="field">
              <input
                id="phone"
                type="text"
                value={ this.state.phone }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.contact.field.phonePlaceholder') }
              />
            </div>
          </div>
        </div>

        <div className="fields-group">
          <div className="field-wrapper">
            <label htmlFor="skype">{ I18n.t('company.profile.form.contact.field.skype') }</label>
            <div className="field">
              <input
                id="skype"
                type="text"
                value={ this.state.skype }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.contact.field.skypePlaceholder') }
              />
            </div>
          </div>

          <div className="field-wrapper">
            <label htmlFor="website">{ I18n.t('company.profile.form.contact.field.website') }</label>
            <div className="field">
              <input
                id="website"
                type="url"
                value={ this.state.website }
                onChange={ this.handleChange }
                placeholder={ I18n.t('company.profile.form.contact.field.websitePlaceholder') }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompanyContactInformation.propTypes = {
  setContactInformation: PropTypes.func.isRequired,
  contactInformation: CompanyContactInformationShape,
  errors: PropTypes.shape({})
};

CompanyContactInformation.defaultProps = {
  contactInformation: undefined
};

export default CompanyContactInformation;
