import firebase from '../config/firebase';
import Project from '../model/Project';

const SAVING_PROJECT = 'company/SAVING_PROJECT';
const SAVED_PROJECT = 'company/SAVED_PROJECT';
const LOADING_ALL_PROJECTS = 'company/LOADING_ALL_PROJECTS';
const LOADED_ALL_PROJECT = 'company/LOADED_ALL_PROJECT';
const LOADING_COMPANY_PROJECTS = 'company/LOADING_COMPANY_PROJECTS';
const LOADED_COMPANY_PROJECT = 'company/LOADED_COMPANY_PROJECT';

const initialState = {
  companyProjects: [],
  companyProjectsLoading: false,
  projects: [],
  projectsLoading: false,
  projectSaved: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SAVING_PROJECT:
      return Object.assign({}, state, {
        projectSaved: false
      });

    case SAVED_PROJECT:
      return Object.assign({}, state, {
        profile: action.payload,
        projectSaved: true
      });

    case LOADING_ALL_PROJECTS:
      return Object.assign({}, state, {
        projectsLoading: true
      });

    case LOADED_ALL_PROJECT:
      return Object.assign({}, state, {
        projectsLoading: false,
        projects: action.payload,
        projectSaved: false
      });

    case LOADING_COMPANY_PROJECTS:
      return Object.assign({}, state, {
        companyProjectsLoading: true
      });

    case LOADED_COMPANY_PROJECT:
      return Object.assign({}, state, {
        companyProjectsLoading: false,
        companyProjects: action.payload
      });

    default:
      return state;
  }
}

export function saveProject(project) {
  return dispatch => {
    dispatch({ type: SAVING_PROJECT });
    
    const database = firebase.database();
    database.ref('projects/' + project.ownerId + '/' + project.id).set(project);

    return dispatch({
      type: SAVED_PROJECT,
      payload: project
    });
  };
}

export function loadAllProjects() {
  return dispatch => {
    dispatch({ type: LOADING_ALL_PROJECTS });

    const database = firebase.database();
    database.ref('projects/').once('value').then(snapshot => {
      const projectsData = snapshot.val();
      const projects = rawDataToProjectList(projectsData);

      return dispatch({
        type: LOADED_ALL_PROJECT,
        payload: projects
      });
    });
  };
}

export function loadCompanyProjects(userId) {
  return dispatch => {
    dispatch({ type: LOADING_COMPANY_PROJECTS });

    const database = firebase.database();
    database.ref('projects/' + userId).once('value').then(snapshot => {
      const projectsData = snapshot.val();
      const projects = companyRawDataToProjectList(projectsData);

      return dispatch({
        type: LOADED_COMPANY_PROJECT,
        payload: projects
      });
    });
  };
}

function rawDataToProjectList(rawData) {
  return Object.keys(rawData).map(userId => {
    return Object.keys(rawData[userId]).map(projectId => {
      return new Project(rawData[userId][projectId]);
    });
  }).reduce((flatArray, projects) => flatArray.concat(projects), []);
}

function companyRawDataToProjectList(rawData) {
  if (rawData) {
    return Object.keys(rawData).map(key => new Project(rawData[key]));
  }
  return [];
}
