import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadAvailableConsultations } from '../../reducers/data';

class ConsultationsForm extends Component {

  componentWillMount() {
    this.props.loadAvailableConsultations();
  }

  handleRadioChange = (consultationId) => {
      if(consultationId === ''){
        this.props.setConsultation({
          consultationId: consultationId,
          needConsultation: false
        });
      }
      else{
        this.props.setConsultation({
          consultationId: consultationId,
          needConsultation: true
        });
      }
  }

  isConsultationSelected = (consultationId) => {
    const selectedConsultationId = this.props.selectedConsultation.consultationId
    return consultationId === selectedConsultationId;
  }

  isObject = (entity) => {
    return entity && typeof entity === 'object' && entity.constructor === Object;
  }

  displayAvailableConsultations = () => {
    const { availableConsultations } = this.props;
    return availableConsultations.map((availableConsultation) => {
      const id = availableConsultation.consultationId;
      return (
          <div
            className="checkbox-consultation field-wrapper"
            key={ `consultation-${id}` }>
            <input
              id={ `consultation-${id}` }
              type="radio"
              value={ id }
              checked={ this.isConsultationSelected(id) }
              onChange={ () => this.handleRadioChange(id) }
            />
            <label htmlFor={ `consultation-${id}` }>{ availableConsultation.label + ': ' +  availableConsultation.price + '$'}</label>
          </div>
      );
    });
  }

  render() {
    return (
      <div className="radio-group">
        {this.displayAvailableConsultations()}
        <div
          className="checkbox-consultation field-wrapper"
          key='default'>
            <input
              id='default'
              key='default'
              type="radio"
              value='no'
              checked={ this.isConsultationSelected('') }
              onChange={ () => this.handleRadioChange('') }
            />
            <label htmlFor={ 'default' }>Non merci</label>
          </div>
      </div>
    );
  }
}

ConsultationsForm.propTypes = {
  availableConsultations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadAvailableConsultations: PropTypes.func.isRequired,
  setConsultation: PropTypes.func.isRequired,
  errors: PropTypes.shape({})
};

const mapStateToProps = state => ({
  availableConsultations: state.data.availableConsultations,
  skillsLoading: state.data.skillsLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAvailableConsultations
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsForm);