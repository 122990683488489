import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { loadWorkerProfile } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';

class CompanyHeaderSwitchProfile extends Component {

  componentDidMount() {
    this.props.loadWorkerProfile(this.props.user.uid);
  }

  showCallToAction() {
    if (this.props.workerProfile && this.props.workerProfile.isValid()) {
      return <Link to="/talent" className="btn transparent"><span>{ I18n.t('nav.company.switchProfileButtonLabel') }</span></Link>;
    } else {
      return <Link to="/talent/profile" className="btn transparent"><span>{ I18n.t('nav.company.createWorkerProfileButtonLabel') }</span></Link>;
    }
  }

  render() {
    if (!this.props.workerProfileLoading) {
      return (
        <div className="grid-item worker-switch">
          <p><em>{ I18n.t('nav.company.workOnProjectLabel') }</em></p>
          { this.showCallToAction() }
        </div>
      );

    }
    return null;
  }
}

CompanyHeaderSwitchProfile.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string
  }),
  workerProfile: WorkerShape,
  workerProfileLoading: PropTypes.bool.isRequired,
  loadWorkerProfile: PropTypes.func.isRequired
};

CompanyHeaderSwitchProfile.defaultProps = {
  user: undefined,
  profileLoading: true
};

const mapStateToProps = state => ({
  workerProfile: state.company.profile,
  workerProfileLoading: state.company.profileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadWorkerProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyHeaderSwitchProfile);
