import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { loadWorkerProfile } from '../../reducers/worker';

import { WorkerShape } from '../../model/Worker';

import iconCompleteProfile from '../../assets/img/icon-complete-profile.svg';
import iconClose from '../../assets/img/icon-close.svg';

class WorkerDashboardSummary extends Component {

  showNotification() {
    if (!this.props.workerProfile || !this.props.workerProfile.isValid()) {
      return (
        <div className="grid">
          <div className="grid-item">
            <section className="section section--tile notification">
              <div className="btn--close">
                <img src={iconClose} alt=""/>
              </div>
              <div className="content">
                <img src={iconCompleteProfile} alt=""/>
                <h3>{ I18n.t('dashboard.worker.notification.title') }</h3>
                <p>{ I18n.t('dashboard.worker.notification.text') }</p>
                <Link to="/talent/profile" className="btn small reverse"><span>{ I18n.t('dashboard.worker.notification.linkLabel') }</span></Link>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return null;
  }

  showMyProjects() {
    // Add a condition to show the projects when the feature is available. See CompanyDashboardSummary.js
    return (
      <div className="content">
        <img src={iconCompleteProfile} alt=""/>
        <h3>{ I18n.t('dashboard.worker.noProject.title') }</h3>
        <p>{ I18n.t('dashboard.worker.noProject.text') }</p>
        <Link to="/talent/projects" className="btn small reverse"><span>{ I18n.t('dashboard.worker.noProject.linkLabel') }</span></Link>
      </div>
    );
  }

  showMyProjectsSection() {
    if (this.props.workerProfile && this.props.workerProfile.isValid()) {
      return (
        <div className="grid-item">
          <h2><span>{ I18n.t('dashboard.worker.myProjects') }</span></h2>
          <section className="section section--tile">
            { this.showMyProjects() }
          </section>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="grid">
        { this.showNotification() }
        { this.showMyProjectsSection() }
      </div>
    );
  }
}

WorkerDashboardSummary.propTypes = {
  workerProfile: WorkerShape
};

WorkerDashboardSummary.defaultProps = {
  workerProfile: undefined
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  workerProfile: state.worker.profile,
  workerProfileLoading: state.worker.profileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadWorkerProfile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkerDashboardSummary);
