import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import logoWhite from '../../assets/img/logo-white.svg';

import FirebaseForgotPasswordForm from '../forms/FirebaseForgotPasswordForm';

class ForgotPassword extends Component {


  render() {
    return (
      <div className="page page--login">
        <section className="section section--splash">
          <img src={logoWhite} alt="Les Talents M." />
        </section>
        <section className="section section--login">
          <div>
            <h1>{I18n.t('login.forgotPassword.title')}</h1>

            <FirebaseForgotPasswordForm />

            <p><a onClick={() => this.props.showHomePage()}>{ I18n.t('login.backToLogin') }</a></p>
          </div>
          
        </section>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  showHomePage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  showHomePage: () => push('/')
}, dispatch)

export default connect(null, mapDispatchToProps)(ForgotPassword)
