import firebase from '../config/firebase';

const DECIMAL_RADIX = 10;

const FETCH_ACTIVITY_FIELDS = 'data/FETCH_ACTIVITY_FIELDS';
const RECEIVE_ACTIVITY_FIELDS = 'data/RECEIVE_ACTIVITY_FIELDS';
const FETCH_COMPANY_SIZES = 'data/FETCH_COMPANY_SIZES';
const FETCH_AVAILABLE_CONSULTATIONS = 'data/FETCH_AVAILABLE_CONSULTATIONS';
const RECEIVE_AVAILABLE_CONSULTATIONS = 'data/RECEIVE_AVAILABLE_CONSULTATIONS';
const RECEIVE_COMPANY_SIZES = 'data/RECEIVE_COMPANY_SIZES';
const FETCH_PROJECT_STATUSES = 'data/FETCH_PROJECT_STATUSES';
const RECEIVE_PROJECT_STATUSES = 'data/RECEIVE_PROJECT_STATUSES';
const FETCH_SKILLS = 'data/FETCH_SKILLS';
const FETCH_CURRENCIES = 'data/FETCH_CURRENCIES';
const RECEIVE_CURRENCIES = 'data/RECEIVE_CURRENCIES';
const RECEIVE_SKILLS = 'data/RECEIVE_SKILLS';

const initialState = {
  activityFieldsLoading: false,
  activityFields: [],
  companySizesLoading: false,
  companySizes: [],
  consultationsLoading: false,
  availableConsultations: [],
  projectStatusesLoading: false,
  projectStatuses: [],
  skillsLoading: false,
  groupedSkills: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_FIELDS:
      return Object.assign({}, state, {
        activityFieldsLoading: true
      });

    case RECEIVE_ACTIVITY_FIELDS:
      return Object.assign({}, state, {
        activityFieldsLoading: false,
        activityFields: action.payload
      });
    
    case FETCH_COMPANY_SIZES:
      return Object.assign({}, state, {
        companySizesLoading: true
      });

    case RECEIVE_COMPANY_SIZES:
      return Object.assign({}, state, {
        companySizesLoading: false,
        companySizes: action.payload
      });

    case FETCH_AVAILABLE_CONSULTATIONS:
      return Object.assign({}, state, {
        consultationsLoading: true
      });

    case RECEIVE_AVAILABLE_CONSULTATIONS:
      return Object.assign({}, state, {
        consultationsLoading: false,
        availableConsultations: action.payload
      });
    
    case FETCH_PROJECT_STATUSES:
      return Object.assign({}, state, {
        projectStatusesLoading: true
      });

    case RECEIVE_PROJECT_STATUSES:
      return Object.assign({}, state, {
        projectStatusesLoading: false,
        projectStatuses: action.payload
      });

    case FETCH_SKILLS:
      return Object.assign({}, state, {
        skillsLoading: true
      });

    case RECEIVE_SKILLS:
      return Object.assign({}, state, {
        skillsLoading: false,
        groupedSkills: action.payload
      });
    case FETCH_CURRENCIES:
      return Object.assign({}, state, {
        currenciesLoading: true
      });

    case RECEIVE_CURRENCIES:
      return Object.assign({}, state, {
        currenciesLoading: false,
        currencies: action.payload
      });

    default:
      return state;
  }
}

export function loadActivityFields() {
  return dispatch => {
    dispatch({ type: FETCH_ACTIVITY_FIELDS });

    const database = firebase.database();
    database.ref('activityFields/').once('value').then(snapshot => {
      const fields = snapshotToObjectList(snapshot.val());
      return dispatch({
        type: RECEIVE_ACTIVITY_FIELDS,
        payload: fields
      });
    });
  };
}

export function loadCompanySizes() {
  return dispatch => {
    dispatch({ type: FETCH_COMPANY_SIZES });

    const database = firebase.database();
    database.ref('companySizes/').once('value').then(snapshot => {
      const fields = snapshotToObjectList(snapshot.val());
      return dispatch({
        type: RECEIVE_COMPANY_SIZES,
        payload: fields
      });
    });
  };
}

export function loadAvailableConsultations() {
  return dispatch => {
    dispatch({ type: FETCH_AVAILABLE_CONSULTATIONS });

    const database = firebase.database();
    database.ref('availableConsultations/').once('value').then(snapshot => {
      const availableConsultations = snapshotToObjectList(snapshot.val());

        return dispatch({
          type: RECEIVE_AVAILABLE_CONSULTATIONS,
          payload: availableConsultations
        });
    });
  };
}

export function loadProjectStatuses() {
  return dispatch => {
    dispatch({ type: FETCH_PROJECT_STATUSES });

    const database = firebase.database();
    database.ref('projectStatuses/').once('value').then(snapshot => {
      const fields = snapshotToObjectList(snapshot.val());
      return dispatch({
        type: RECEIVE_PROJECT_STATUSES,
        payload: fields
      });
    });
  };
}

export function loadSkills() {
  return dispatch => {
    dispatch({ type: FETCH_SKILLS });

    const database = firebase.database();
    database.ref('skillGroups/').once('value').then(snapshot => {
      const groups = snapshotToObjectList(snapshot.val());

      database.ref('skills/').once('value').then(snapshot => {
        const skills = snapshotToObjectList(snapshot.val());

        return dispatch({
          type: RECEIVE_SKILLS,
          payload: groupSkills(groups, skills)
        });
      });
    });
  };
}

export function loadCurrencies() {
  return dispatch => {
    dispatch({ type: FETCH_CURRENCIES });

    const database = firebase.database();
    database.ref('currencies/').once('value').then(snapshot => {
      const currencies = snapshotToObjectList(snapshot.val());
      return dispatch({
        type: RECEIVE_CURRENCIES,
        payload: currencies
      });
    });
  };
}

function snapshotToObjectList(snapshot) {
  return Object.keys(snapshot).map(id => {
    return Object.assign({}, snapshot[id], { id: parseInt(id, DECIMAL_RADIX) });
  });
}

function groupSkills(groups, skills) {
  return groups.map(group => {
    return Object.assign({}, group, 
      { 
        skills: skills.filter(skill => {
          return skill.groupId === group.id;
        })
      }
    );
  });
}