import React, { Component } from 'react'
import PropTypes from 'prop-types';

import iconAddSkill from '../../assets/img/icon-add-skills.svg';
import EvaluatorForm from './EvaluatorForm';

class SkillsSelector extends Component {

  toggleList(id) {
    const skillList = document.querySelector(`.skill-${ id }`);
    skillList.classList.toggle('list-hidden');
  }

  handleChange(skillId, score) {
    if (score) {
      this.updateSkillScore(skillId, score);
    } else {
      this.updateSkillSelected(skillId);
    }
  }

  updateSkillScore(skillId, score) {
    const skills = this.props.selectedSkills;
    skills.forEach(evaluation => {
      if (evaluation.skillId === skillId) {
        Object.assign(evaluation, { score });
      }
    });
    this.props.setSkillsSelected(skills);
  }

  isSkillSelected(skillId) {
    const skills = this.props.selectedSkills;
    return skills.filter(evaluation => evaluation.skillId === skillId).length > 0;
  }

  updateSkillSelected(skillId) {
    const skills = this.props.selectedSkills;
    if (this.isSkillSelected(skillId)) {
      this.props.setSkillsSelected(skills.filter(evaluation => {
        return evaluation.skillId !== skillId;
      }));
    } else {
      skills.push({ skillId });
      this.props.setSkillsSelected(skills);
    }
  }

  showEvaluatorForSkill(skillId) {
    return this.props.withEvaluator && this.isSkillSelected(skillId);
  }

  getCurrentScore(skillId) {
    const evaluation = this.props.selectedSkills.find(evaluation => evaluation.skillId === skillId);
    if (evaluation) {
      return evaluation.score;
    }
    return;
  }

  showEvaluator(skillId) {
    if (this.showEvaluatorForSkill(skillId)) {
      const error = this.props.errors ? this.props.errors[skillId] : undefined;
      return (
        <EvaluatorForm
          referenceId={ skillId }
          currentScore={ this.getCurrentScore(skillId) }
          onValueSelected={ this.handleChange.bind(this) }
          error={ error }
        />
      );
    }
    return null;
  }

  displayGroups() {
    return this.props.groupedSkills.map(group => {
      return (
        <div key={ group.id } className={ `skills-list skill-${ group.id }` }>
          <p onClick={ () => this.toggleList(group.id) }>{ group.label }</p>
          <ul>
            { this.displayGroupSkills(group) }
          </ul>
        </div>
      );
    });
  }

  displayGroupSkills(group) {
    return group.skills.map(skill => {
      const id = skill.id;
      return (
        <li key={ id }>
          <div className="checkbox-skill">
            <input
              id={ `skill-${id}` }
              type="checkbox"
              value={ id }
              checked={ this.isSkillSelected(id) }
              onChange={ () => this.handleChange(id) }
            />
            <label htmlFor={ `skill-${id}` }>{ skill.label }</label>
          </div>
          { this.showEvaluator(id) }
        </li>
      );
    });
  }

  render() {
    return (
      <div>
        { this.displayGroups() }
        <div className="placeholder">
          <img src={iconAddSkill} alt=""/>
          Ajoutez des compétences pour faire apparaître les métiers associés
        </div>
      </div>
    );
  }
}

SkillsSelector.propTypes = {
  groupedSkills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSkills: PropTypes.arrayOf(PropTypes.shape({
    skillId: PropTypes.number,
    score: PropTypes.string
  })).isRequired,
  setSkillsSelected: PropTypes.func.isRequired,
  withEvaluator: PropTypes.bool,
  errors: PropTypes.shape({})
};

SkillsSelector.defaultProps = {
  withEvaluator: false
};

export default SkillsSelector;
