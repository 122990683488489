const dashboard = {
  title: 'Bienvenue chez les Talents M',
  subTitle: 'Que souhaitez-vous faire ?',
  company: {
    sectionTitle: 'Soumettre des projets',
    buttonLabel: 'Accéder à mon profil Demandeur',
    notification: {
      title: 'Completez votre profil entreprise !',
      text: 'Votre profil vient d\'être créé. Complétez maintenant toutes les informations pour trouver des ressources !',
      linkLabel: 'Compléter mon profil entreprise'
    },
    noProject: {
      title: 'Vous n\'avez pas encore lancé de projet',
      text: 'Soumettez dès maintenant votre projet à notre équipe et cherchez des talents qualifiés pour travailler dessus !',
      linkLabel: 'Soumettre un projet'
    },
    myProjects: 'Mes projets en cours'
  },
  worker: {
    sectionTitle: 'Proposer mes talents',
    buttonLabel: 'Accéder à mon profil Talent',
    notification: {
      title: 'Complète ton profil !',
      text: 'Ton profil vient d\'être créé. Complète maintenant toutes les informations liées à tes passions et expertises pour trouver des projets !',
      linkLabel: 'Compléter mon profil'
    },
    noProject: {
      title: 'Tu n\'as pas encore de projet en cours.',
      text: 'Parcours les projets postés sur Les Talents M et trouve des projets qui te ressemblent.',
      linkLabel: 'Trouver un projet'
    },
    myProjects: 'Mes projets en cours'
  }
};

export default dashboard;